import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { getUnits, settingsData } from '../../data/dummy';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { Autocomplete } from '@mui/material';
import { createProductRequest, getProductRequest, updateProductRequest } from '../../store/products/actions';
import { getSuppliersRequest } from '../../store/suppliers/actions';

const ProductForm = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { product } = useSelector(state => state.products);
  const { suppliers } = useSelector(state => state.suppliers);
  const { currentColor } = useStateContext();
  const [inputValue, setInputValue] = useState('');
  const [feedback, setFeedback] = useState('');
  const [suppliersList, setSuppliersList] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    ref: '',
    category: '',
    name: '',
    unit: 'Piece',
    price: 0,
    tax: settingsData.company_services_tax_factor,
    supplier: '',
    buyPrice: 0,
    initStock: 0,
    stock: 0,
    description: '',
    purshace: false,
    image: '',
  });

  const clearForm = () => {
    setFormData({
      id: null,
      ref: '',
      category: '',
      name: '',
      unit: 'Piece',
      price: 0,
      tax: settingsData.company_services_tax_factor,
      supplier: '',
      buyPrice: 0,
      initStock: 0,
      stock: 0,
      description: '',
      purshace: false,
      image: '',
    });
  }

  useEffect(() => {
      dispatch(getSuppliersRequest());
  } , [dispatch]);

  useEffect(() => {
    if (suppliers) {
      setSuppliersList(suppliers);
      if (id) {
        setInputValue(suppliers?.find(supplier => supplier._id === product.supplier)?.name);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [suppliers]);
  
  useEffect(() => {
    if (id) {
      dispatch(getProductRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (product && id) {
      setFormData({
        id: product._id,
        category: product.category,
        name: product.name,
        unit: product.unit,
        price: product.price,
        tax: product.tax,
        description: product.description,
        supplier: product.supplier,
        buyPrice: product.buyPrice,
        initStock: product.initStock,
        stock: product.stock,
        ref: product.ref,
        purshace: null,
        image: product.image,
      });
    } else {
      clearForm();
    }
  }, [product, id]);

  const validation = () => {
    let isValid = true;
    if (formData.name.length === 0) {
      isValid = false;
      setFeedback('Name is required');
    }
    if (formData.category.length === 0) {
      isValid = false;
      setFeedback('Category is required');
    }
    if (formData.price.length === 0) {
      isValid = false;
      setFeedback('Price is required');
    }
    if (formData.supplier.length === 0) {
      isValid = false;
      setFeedback('Supplier is required');
    }
    return isValid;
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      if (id) {
        dispatch(updateProductRequest(formData, navigate));
      } else {
        dispatch(createProductRequest(formData, navigate));
      }
    }
  }

  if (user?.role > 4) {
    return <NotAuthorized />
  }
    
  return (
    <div className="m-2 md:m-6 p-2 md:p-4 dark:bg-main-dark-bg bg-transparent">
      <form className="relative w-full mx-auto py-6 px-4 sm:px-10 mt-20 sm:mt-0">
          <MdOutlineLibraryAdd className='absolute left-2 top-2 opacity-10 text-slate-400 dark:text-slate-100 text-6xl sm:text-8xl' /> 
          <h3 className='mb-20 mx-auto text-center text-3xl font-bold dark:text-white text-slate-600' 
              style={{ color: currentColor }}>{id ? 'Edit' : 'Add'} Product</h3>
          <div className='flex flex-col sm:flex-row w-full mb-4'>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
              <div className='flex w-full'>
                <div className='w-full sm:w-1/3 px-2 mb-4 sm:mb-0'>
                  <label htmlFor="ref" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Ref</label>
                  <input id="ref"
                        name="ref"
                        type="ref"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.ref}
                        onChange={(event) => setFormData({...formData, ref: event.target.value})}
                  />
                </div>
                <div className='w-full sm:w-2/3 px-2 mb-4 sm:mb-0'>
                  <label htmlFor="category" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Category<span className='text-red-600 ml-2'>*</span></label>
                  <input id="category"
                          name="category"
                          type="category"
                          required
                          className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                          value={formData.category}
                          onChange={(event) => setFormData({...formData, category: event.target.value})}
                  />
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
                <label htmlFor="name" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Product Name<span className='text-red-600 ml-2'>*</span></label>
                <input id="name"
                        name="name"
                        type="text"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.name}
                        onChange={(event) => setFormData({...formData, name: event.target.value})}
                />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row w-full mb-4'>
              <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
                <label htmlFor="customer" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Supplier<span className='text-red-600 ml-2'>*</span></label>
                <Autocomplete
                    id="supplier-auto"
                    value={formData.supplier}
                    onChange={(event, newValue) => {
                      setFormData({...formData, supplier: newValue._id});
                      setFeedback('');
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={suppliersList}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option) => option._id === formData.supplier}
                    getOptionLabel={(option) => option.name || inputValue}
                    renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <input type="text" 
                                id="supplier" 
                                name="supplier" 
                                placeholder='Search supplier'
                                className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                                {...params.inputProps} 
                              />
                        </div>
                    )}
                  />
              </div>     
              <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <div className="flex w-full">
                    <div className="w-full sm:w-2/3 px-2 mb-4 sm:mb-0">
                      <label htmlFor="price" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Unit Price<span className='text-red-600 ml-2'>*</span></label>
                      <input id="price"
                              name="price"
                              type="number"
                              required
                              className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                              value={formData.price}
                              onChange={(event) => setFormData({...formData, price: event.target.value})}
                      />
                    </div>
                    <div className="w-full sm:w-1/3 px-2 mb-4 sm:mb-0">
                      <label htmlFor="tax" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Tax ({`${settingsData?.company_services_tax_factor}%`})</label>
                      <input id="tax"
                              name="tax"
                              type="Number"
                              max={100}
                              className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                              value={formData.tax}
                              onChange={(event) => setFormData({...formData, tax: event.target.value})}
                      />
                    </div>
                  </div>
              </div>
          </div>
          <div className='flex flex-col sm:flex-row w-full mb-4'>
            <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
              <label htmlFor="image" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Image Url</label>
              <input id="image"
                      name="image"
                      type="text"
                      className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                      value={formData.image}
                      onChange={(event) => setFormData({...formData, image: event.target.value})}
              />
              <label htmlFor="description" className="block text-sm dark:text-gray-200 text-gray-600 mb-2 mt-4">Description</label>
              <textarea id="description"
                      name="description"
                      rows={4}
                      className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                      value={formData.description}
                      onChange={(event) => setFormData({...formData, description: event.target.value})}
              />
            </div>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
              <div className='flex flex-col sm:flex-row w-full'>
                    <div className='w-full sm:w-2/3 px-2 mb-4 sm:mb-0'>
                      <label htmlFor="buyPrice" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Buying Price</label>
                      <input id="buyPrice"
                              name="buyPrice"
                              type="number"
                              required
                              className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                              value={formData.buyPrice}
                              onChange={(event) => setFormData({...formData, buyPrice: event.target.value})}
                      />
                    </div>
                    <div className='w-full sm:w-1/3 px-2 mb-4 sm:mb-0'>
                      <label htmlFor="unit" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Unit</label>
                      <select id="unit"
                              name="unit"
                              className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                              value={formData.unit}
                              onChange={(event) => setFormData({...formData, unit: event.target.value})}
                      >
                        {getUnits?.map(unit => <option key={unit} value={unit}>{unit}</option>)}
                      </select>
                    </div>
              </div>
              {
                !id ? ( 
                <div className='mt-4 flex flex-col sm:flex-row items-center w-full'>
                    <div className='w-1/2 px-2 mb-4 sm:mb-0'>
                      <label htmlFor="initStock" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Initial Stock</label>
                      <input id="initStock"
                              name="initStock"
                              type="number"
                              required
                              className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                              value={formData.initStock}
                              onChange={(event) => setFormData({...formData, initStock: event.target.value})}
                      />
                    </div>
                    <div className='w-1/2 px-2 pt-2 mb-4 sm:mb-0'>
                        <label htmlFor="save" className="cursor-pointer flex items-center text-md dark:text-gray-200 text-gray-600">
                        <input id="save"
                                name="save"
                                type="checkbox"
                                required
                                className='mr-4'
                                checked={formData.purshace}
                                onChange={(event) => setFormData({ ...formData, purshace: event.target.checked })}
                        />
                        <b className='whitespace-nowrap	text-xs sm:text-sm'>Save a purchace: 
                        <br />
                        {formData.buyPrice * formData.initStock} {settingsData.organization_currency}</b></label>
                    </div>
              </div>) : (
                <div className='mt-4 flex flex-col sm:flex-row items-center w-full'>
                  <div className='w-2/3 px-2 mb-4 sm:mb-0'>
                    <label htmlFor="stock" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">In Stock</label>
                    <p><strong>{formData.stock || 0} {formData.unit}s</strong></p>
                  </div>
                </div>
              )
              }
            </div>
          </div>
          <div className='mt-10 mb-4 flex justify-center items-center'>
            <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md text-white font-bold focus:outline-none'
                    style={{ backgroundColor: currentColor }}
                    type='submit'
                    onClick={(e) => handleSubmit(e)}>
              {id ? 'Edit' : 'Add' } Product
            </button>
            <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md bg-white borderfont-bold focus:outline-none'
                    style={{ borderColor: currentColor, color: currentColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/crm/products');
                    }}>
              Cancel
            </button>
          </div>
          <p className='text-center w-full text-red-400 mt-4'>{feedback}</p>
      </form>
    </div>
  );
};
export default ProductForm;
