//Appointments
//Get Appointments
export const GET_APPOINTMENTS_REQUEST = "GET_APPOINTMENTS_REQUEST";
export const GET_APPOINTMENTS_RECEIVE = "GET_APPOINTMENTS_RECEIVE";
//Get Appointment
export const GET_APPOINTMENT_REQUEST = "GET_APPOINTMENT_REQUEST";
export const GET_APPOINTMENT_RECEIVE = "GET_APPOINTMENT_RECEIVE";
//Create Appointment
export const CREATE_APPOINTMENT_REQUEST = "CREATE_APPOINTMENT_REQUEST";
export const CREATE_APPOINTMENT_RECEIVE = "CREATE_APPOINTMENT_RECEIVE";
//Update Appointment
export const UPDATE_APPOINTMENT_REQUEST = "UPDATE_APPOINTMENT_REQUEST";
export const UPDATE_APPOINTMENT_RECEIVE = "UPDATE_APPOINTMENT_RECEIVE";
//Delete Appointment
export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";
export const DELETE_APPOINTMENT_RECEIVE = "DELETE_APPOINTMENT_RECEIVE";
//List customers
export const LIST_CUSTOMERS_REQUEST = "LIST_CUSTOMERS_REQUEST";
export const LIST_CUSTOMERS_RECEIVE = "LIST_CUSTOMERS_RECEIVE";
//Get Next Appointment
export const GET_NEXT_APPOINTMENT_REQUEST = "GET_NEXT_APPOINTMENT_REQUEST";
export const GET_NEXT_APPOINTMENT_RECEIVE = "GET_NEXT_APPOINTMENT_RECEIVE";