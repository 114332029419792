import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addUserRequest, editUserRequest, getUserDataRequest } from '../../store/users/actions';
import { useParams } from 'react-router';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';

const EmployeeForm = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { userData } = useSelector(state => state.users);
  const { currentColor } = useStateContext();
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    phone: '',
    email: '',
    password: '',
    role: 2,
    avatar: '',
    is_actif: true,
  });

  useEffect(() => {
    if (id) {
      dispatch(getUserDataRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (userData && id) {
      setFormData({
        id: userData._id,
        name: userData.name,
        phone: userData.phone,
        email: userData.email,
        password: userData.password,
        role: userData.role,
        avatar: userData.avatar,
        is_actif: userData.is_actif,
      });
    } else {
      setFormData({
        id: null,
        name: '',
        phone: '',
        email: '',
        password: '',
        role: 0,
        avatar: '',
        is_actif: true,
      });
    }
  }, [userData, id]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      dispatch(editUserRequest(formData));
    } else {
      dispatch(addUserRequest(formData));
      setTimeout(() => {
        navigate('/hr/employees');
      } , 1000);
    }
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }
    
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-main-dark-bg bg-white rounded-3xl">
      <div className="w-full mx-auto py-10 px-20 bg-gray-100 bg-gradient-to-tr from-transparent to-gray-100 rounded-md dark:bg-secondary-dark-bg">
          <h3 className='mb-10 mx-auto text-center text-3xl font-bold dark:text-white text-slate-600' 
              style={{ color: currentColor }}>{id ? 'Edit' : 'Add'} User</h3>
          <div className='flex w-full'>
            <div className='w-1/2 px-2'>
              <div className='mb-4'>
                <label htmlFor="name" className="text-sm text-gray-600 mb-2">User name</label>
                <input id="name"
                        name="name"
                        type="text"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.name}
                        onChange={(event) => setFormData({...formData, name: event.target.value})}
                />
              </div>
              <div className='mb-4'>
                <label htmlFor="phone" className="text-sm text-gray-600 mb-2">Phone Number</label>
                <input id="phone"
                        name="phone"
                        type="tel"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.phone}
                        onChange={(event) => setFormData({...formData, phone: event.target.value})}
                />
              </div>
              <div className='mb-4'>
                <label htmlFor="email" className="text-sm text-gray-600 mb-2">Email address</label>
                <input id="email"
                        name="email"
                        type="email"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.email}
                        onChange={(event) => setFormData({...formData, email: event.target.value})}
                />
              </div>
              <div className='mb-4'>
                <label htmlFor="password" className="text-sm text-gray-600 mb-2">Password</label>
                <input id="password"
                        name="password"
                        type="password"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.password}
                        onChange={(event) => setFormData({...formData, password: event.target.value})}
                />
              </div>
            </div>
            <div className='w-1/2 px-2'>
              <div className='mb-4'>
                <label htmlFor="role" className="text-sm text-gray-600 mb-2">Role</label>
                <select id="role"
                        name="role"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.role}
                        onChange={(event) => setFormData({...formData, role: event.target.value})}
                >
                  {user?.role === 1 && <option value={1}>Super Admin</option>}
                  {user?.role === 1 && <option value={2}>Admin</option>}
                  <option value={3}>Operator</option>
                  <option value={4}>Employee</option>
                </select>
              </div>
              <div className='mb-4'>
                <label htmlFor="is_actif" className="text-sm text-gray-600 mb-2">Is Actif</label>
                <select id="is_actif"
                        name="is_actif"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.is_actif}
                        onChange={(event) => setFormData({...formData, is_actif: event.target.value})}
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div className='mb-4'>
                <label htmlFor="avatar" className="text-sm text-gray-600 mb-2">Avatar URL</label>
                <input id="avatar"
                        name="avatar"
                        type="text"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none'
                        value={formData.avatar}
                        onChange={(event) => setFormData({...formData, avatar: event.target.value})}
                />
              </div>
            </div>
          </div>
          <div className='mt-10 mb-4 flex justify-center items-center'>
            <button className='w-48 mx-3 py-3 px-6 rounded-md text-white font-bold focus:outline-none'
                    style={{ backgroundColor: currentColor }}
                    onClick={(e) => handleSubmit(e)}>
              {id ? 'Edit' : 'Add' } User
            </button>
            <button className='w-48 mx-3 py-3 px-6 rounded-md bg-white borderfont-bold focus:outline-none'
                    style={{ borderColor: currentColor, color: currentColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/hr/employees');
                    }}>
              Cancel
            </button>
          </div>
      </div>
    </div>
  );
};
export default EmployeeForm;
