import React, { useEffect } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { getNotificationsRequest } from '../store/notifications/actions';
import { AiOutlineSetting } from 'react-icons/ai';
import { GoProject } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const { i18n } = useTranslation();
  const { currentColor } = useStateContext();
  const dispatch = useDispatch();
  const { notifications_list, notifications_count } = useSelector(state => state.notifications);
  
  useEffect(() => {
    const payload = { page: 0 };
    dispatch(getNotificationsRequest(payload));
  }, [dispatch]);

  return (
    <div className={`nav-item absolute ${i18n.language === 'ar' ? 'left-5 md:left-40' : 'right-5 md:right-40'} top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96`}>
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200">Notifications</p>
          <button type="button" className="text-white text-xs rounded p-1 px-2 bg-orange-theme "> {notifications_count} New</button>
        </div>
        <Button icon={<MdOutlineCancel />} color="rgb(153, 171, 180)" bgHoverColor="light-gray" size="2xl" borderRadius="50%" />
      </div>
      <div className="mt-5 ">
        {notifications_list?.map((item, index) => (
          <div key={index} className="flex items-center leading-8 gap-5 border-b-1 border-color p-3">
            {
            item.type === 'system' ? <AiOutlineSetting className={`text-6xl ${item.seen && 'fill-gray-200'}`} style={!item.seen && {fill: currentColor}} /> 
            : item.type === 'Project' ? <GoProject className={`text-2xl ${item.seen && 'fill-gray-200'}`} style={!item.seen && {fill: currentColor}} />
            : <AiOutlineSetting className="text-gray-200 text-6xl" />
            }
            <div>
              <p className="font-semibold dark:text-gray-200 uppercase">{item.type}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400"> {item.message} </p>
            </div>
          </div>
        ))}
        <div className="mt-5">
          <Button color="white" bgColor={currentColor} text="See all notifications" borderRadius="10px" width="full" />
        </div>
      </div>
    </div>
  );
};

export default Notification;
