import * as TYPES from "./types";

const initialState = {
  projects: [],
  pageCount: 0,
  currentPage: 0,
  totalProjects: 0,
  project: {},
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    //Get projects
    case TYPES.GET_PROJECTS_RECEIVE: {
      return {
        ...state,
        projects: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalProjects: action.payload.headers["x-pagination-total"],
      };
    }
    //Get project
    case TYPES.GET_PROJECT_RECEIVE: {
      return {
        ...state,
        project: action.payload,
      };
    }
    //Suspend project
    case TYPES.SUSPEND_PROJECT_RECEIVE: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project._id === action.payload._id) {
            return action.payload;
          }
          return project;
        }),
      };
    }
    //Active project
    case TYPES.ACTIVE_PROJECT_RECEIVE: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project._id === action.payload._id) {
            return action.payload;
          }
          return project;
        }),
      };
    }
    //Close project
    case TYPES.CLOSE_PROJECT_RECEIVE: {
      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project._id === action.payload._id) {
            return action.payload;
          }
          return project;
        }),
      };
    }
    //Get My Projects
    case TYPES.GET_MY_PROJECTS_RECEIVE: {
      return {
        ...state,
        projects: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalProjects: action.payload.headers["x-pagination-total"],
      };
    }
    default:
      return state;
  }
}
