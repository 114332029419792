import * as TYPES from "./types";

const initialState = {
  products: [],
  product: {},
  pageCount: 0,
  currentPage: 0,
  totalCount: 0,
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    //Get products
    case TYPES.GET_PRODUCTS_RECEIVE: {
      return {
        ...state,
        products: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalCount: action.payload.headers["x-pagination-total"],
      }
    }
    //Get product
    case TYPES.GET_PRODUCT_RECEIVE: {
      return {
        ...state,
        product: action.payload,
      }
    }
    //Create product
    case TYPES.CREATE_PRODUCT_RECEIVE: {
      return {
        ...state,
        products: [...state.products, action.payload],
      }
    }
    //Update product
    case TYPES.UPDATE_PRODUCT_RECEIVE: {
      return {
        ...state,
        products: state.products.map(product => {
          if (product._id === action.payload._id) {
            return action.payload;
          }
          return product;
        }
        ),
      }
    }
    //Delete product
    case TYPES.DELETE_PRODUCT_RECEIVE: {
      return {
        ...state,
        products: state.products.filter(product => product._id !== action.id),
      }
    }
    //single Purshace
    case TYPES.SINGLE_PURCHASE_RECEIVE: {
      return {
        ...state,
        product: action.payload,
        products: state.products.map(product => {
          if (product._id === action.payload._id) {
            return action.payload;
          }
          return product;
        })
      }
    }
    default:
      return state
  }  
}
