import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  // dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Get Notifications
export function* getNotificationsSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getNotificationsRequest, payload);
    yield put(ACTIONS.getNotificationsReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

// Seen Notifications
export function* seenNotificationsSaga({ payload }) {
  try {
    const { data } = yield call(APIS.seenNotificationsRequest, payload);
    yield put(ACTIONS.seenNotificationsReceive(data));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* notificationsSagas() {
  yield takeLatest(TYPES.GET_NOTIFICATIONS_REQUEST, getNotificationsSaga);
  yield takeLatest(TYPES.SEEN_NOTIFICATIONS_REQUEST, seenNotificationsSaga);
}


