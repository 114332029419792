//Products
//Get Products
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_RECEIVE = "GET_PRODUCTS_RECEIVE";
//Get Product
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_RECEIVE = "GET_PRODUCT_RECEIVE";
//Create Product
export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_RECEIVE = "CREATE_PRODUCT_RECEIVE";
//Update Product
export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_RECEIVE = "UPDATE_PRODUCT_RECEIVE";
//Delete Product
export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_RECEIVE = "DELETE_PRODUCT_RECEIVE";
//Single Purshace
export const SINGLE_PURCHASE_REQUEST = "SINGLE_PURCHASE_REQUEST";
export const SINGLE_PURCHASE_RECEIVE = "SINGLE_PURCHASE_RECEIVE";
//Multiple Purshace
export const MULTIPLE_PURCHASE_REQUEST = "MULTIPLE_PURCHASE_REQUEST";
export const MULTIPLE_PURCHASE_RECEIVE = "MULTIPLE_PURCHASE_RECEIVE";