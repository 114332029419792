//Services
//Get Services
export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_RECEIVE = "GET_SERVICES_RECEIVE";
//Get Service
export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const GET_SERVICE_RECEIVE = "GET_SERVICE_RECEIVE";
//Create Service
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_RECEIVE = "CREATE_SERVICE_RECEIVE";
//Update Service
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_RECEIVE = "UPDATE_SERVICE_RECEIVE";
//Delete Service
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_RECEIVE = "DELETE_SERVICE_RECEIVE";