import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
//import NotAuthorized from '../../components/NotAuthorized';
import Pagination from "../../components/Pagination";
import { getProjectStatus } from "../../data/dummy";
import {
  suspendProjectRequest,
  activeProjectRequest,
  closeProjectRequest,
  getMyProjectsRequest,
} from "../../store/projects/actions";
import { BiCloset } from "react-icons/bi";
import { GiWaterRecycling } from "react-icons/gi";
import { HiOutlineLockClosed } from "react-icons/hi";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { BsPlayCircle, BsStopCircle } from "react-icons/bs";
import NotAuthorized from "../../components/NotAuthorized";

const MyProjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projects, pageCount, currentPage, totalCount } = useSelector(
    (state) => state.projects
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmCloseProject, setConfirmCloseProject] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const { currentColor } = useStateContext();
  const { user } = useSelector((state) => state.auth);
  const [payload, setPayload] = useState({
    search: "",
    status: 0,
    page: 0,
  });

  useEffect(() => {
    dispatch(getMyProjectsRequest(payload));
  }, [dispatch, payload, user.role]);

  if (user?.role > 7) {
    console.log("NotAuthorized", user.role);
    return <NotAuthorized />;
  }

  const onPageChange = (page) => {
    if (page.selected !== currentPage + 1) {
      setPayload({ ...payload, page: page.selected });
    }
  };

  const handleSuspendProject = () => {
    dispatch(suspendProjectRequest(selectedProjectId));
    setTimeout(() => {
      setConfirmModal(false);
    }, 800);
  };

  const handleCloseProject = () => {
    dispatch(closeProjectRequest(selectedProjectId));
    setTimeout(() => {
      setConfirmCloseProject(false);
    }, 800);
  };

  const activeProject = (id) => {
    dispatch(activeProjectRequest(id));
  };

  return (
    <div className="m-2 md:m-6 p-2 md:p-6">
      <div className="flex justify-between items-center mt-20 sm:mt-0">
        <p
          className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	"
          style={{ color: currentColor }}
        >
          {totalCount && <b>{totalCount}</b>} Projects
        </p>
      </div>
      <div className="w-full flex justify-end items-center py-4">
        <input
          type="text"
          className="w-1/4 px-2 sm:px-4 py-3 bg-gray-100 focus:outline-none"
          placeholder="Search By Name"
          value={payload.search}
          onChange={(e) => setPayload({ ...payload, search: e.target.value })}
        />
        <select
          className="ml-4 w-1/4 px-2 sm:px-4 py-3 bg-gray-100 focus:outline-none"
          value={payload.status}
          onChange={(e) => setPayload({ ...payload, status: e.target.value })}
        >
          <option value="">All Status</option>
          {getProjectStatus.map((status) => (
            <option key={status.id} value={status.id}>
              {status.name}
            </option>
          ))}
        </select>
      </div>
      <div className="projects_container mt-4">
        <div className="flex justify-center flex-wrap p-2 sm:p-0">
          {projects?.map((project) => (
            <div key={project._id} className="w-full sm:w-1/3 mb-3 p-4">
              <div className="relative h-64 w-full transition-all duration-800 shadow-lg rounded-md  p-3 bg-slate-100 dark:bg-slate-500">
                <h2 className="text-xl font-bold text-center mt-1 mb-2 dark:text-slate-100">
                  {project.name}
                </h2>
                <div className="flex justify-start items-center py-2">
                  <img
                    src={project.customer?.avatar || "/images/user.png"}
                    alt="Avatar"
                    className="w-16 h-16 rounded-full border-2 border-slate-200 object-cover"
                    onClick={() =>
                      navigate(`/crm/customers/view/${project.customer?._id}`)
                    }
                  />
                  <div className="ml-4">
                    <h4
                      className="text-sm font-bold text-left mb-1 dark:text-slate-100"
                      onClick={() =>
                        navigate(`/crm/customers/view/${project.customer?._id}`)
                      }
                    >
                      {project.customer?.name}
                    </h4>
                    {project.customer?.website?.length > 0 && (
                      <h6 className="text-xs dark:text-slate-100">
                        <a
                          href={project.customer?.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {project.customer.website}
                        </a>
                      </h6>
                    )}
                  </div>
                </div>
                <div className="mt-2 mb-4">
                  <div className="flex justify-center items-center mb-2">
                    <p className="flex items-center text-gray-800 dark:text-slate-100 text-xs mx-2">
                      <Tooltip title="Project Start Date">
                        <span>
                          <BsPlayCircle className="text-lg mr-1 text-slate-600 dark:text-slate-200" />
                        </span>
                      </Tooltip>
                      <strong className="text-slate-800 dark:text-slate-100">
                        {moment(project.order?.date).format("DD/MM/YYYY")}
                      </strong>
                    </p>
                    <p className="flex items-center text-gray-800 dark:text-slate-100 text-xs mx-2">
                      <Tooltip title="Project Deadline">
                        <span>
                          <BsStopCircle className="text-lg mr-1 text-slate-600 dark:text-slate-200" />
                        </span>
                      </Tooltip>
                      <strong className="text-slate-800 dark:text-slate-100">
                        {moment(project.deadline).format("DD/MM/YYYY")}
                      </strong>
                    </p>
                  </div>
                  <p className="text-gray-800 dark:text-slate-100 text-xs mb-1">
                    <span>Notes: </span>
                    <strong>{project.notes}</strong>
                  </p>
                </div>
                <div className="absolute bottom-2 left-0 w-full flex justify-between items-center px-4">
                  <div className="flex justify-center items-center">
                    <span
                      className="block rounded-full w-4 h-4 mr-2 shadow-xl"
                      style={{
                        backgroundColor: getProjectStatus.find(
                          (status) => status.id === project.status
                        )?.color,
                      }}
                    ></span>
                    <span className="text-sm font-bold dark:text-slate-100">
                      {
                        getProjectStatus.find(
                          (status) => status.id === project.status
                        )?.name
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination
        pageCount={pageCount}
        onPageChange={(page) => onPageChange(page)}
      />
      <div
        className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${
          confirmModal
            ? "opacity-100 pointer-events-auto	"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className="text-lg text-bold mb-6">Confirm Suspend Project?</p>
          <div className="flex justify-end">
            <button
              className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md"
              onClick={() => setConfirmModal(false)}
            >
              Cancel
            </button>
            <button
              className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md"
              onClick={() => handleSuspendProject()}
            >
              Suspend
            </button>
          </div>
        </div>
      </div>
      <div
        className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${
          confirmCloseProject
            ? "opacity-100 pointer-events-auto	"
            : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className="text-lg text-bold mb-6">Confirm Close Project?</p>
          <div className="flex justify-end">
            <button
              className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md"
              onClick={() => setConfirmCloseProject(false)}
            >
              Cancel
            </button>
            <button
              className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md"
              onClick={() => handleCloseProject()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyProjects;
