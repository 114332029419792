import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { getCustomerStatisticsRequest } from "../../store/customers/actions";
import {
  getCustomerStatus,
  getOrderStatus,
  settingsData,
} from "../../data/dummy";
import {
  getMyOrdersRequest,
  getOrdersStatisticsRequest,
} from "../../store/orders/actions";
import LineChart from "../../components/Charts/LineChart";
import { useTranslation } from "react-i18next";
import { getMyStatisticsRequest } from "../../store/auth/actions";
import { getMyProjectsRequest } from "../../store/projects/actions";

const RevenderHomePage = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { totalCount } = useSelector((state) => state.orders);
  const { totalProjects } = useSelector((state) => state.projects);
  const { user } = useSelector((state) => state.auth);
  const { customersStatistics } = useSelector((state) => state.customers);
  const { ordersStatistics } = useSelector((state) => state.orders);
  const [payload, setPayload] = useState({
    search: "",
    status: 0,
    page: 0,
  });
  useEffect(() => {
    if (user?.role < 3) {
      dispatch(getCustomerStatisticsRequest());
      dispatch(getOrdersStatisticsRequest());
    } else if (user?.role > 5) {
      dispatch(getMyStatisticsRequest());
    }
  }, [dispatch, user?.role]);

  useEffect(() => {
    dispatch(getMyOrdersRequest(payload));
    dispatch(getMyProjectsRequest(payload));
  }, [payload, dispatch]);

  return (
    <div className="m-2 md:m-6 p-2 md:p-4">
      <div className="w-full flex flex-col sm:flex-row mt-20 sm:mt-0">
        <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
          <div
            className={`${
              settingsData.company_type === "Products"
                ? "h-36 2xl:h-48"
                : "h-40 2xl:h-52"
            } relative cursor-pointer mx-4 py-6 px-4 rounded-sm shadow-md transition-all duration-800 bg-slate-100 hover:bg-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600`}
            onClick={() => navigate("/crm/my-orders")}
          >
            <span
              className="block text-xl font-bold mb-2"
              style={{ color: currentColor }}
            >
              {t("Orders")}&nbsp;:&nbsp;{totalCount || 0}
            </span>
            <div className="flex items-end">
              <div className="w-4/5">
                <span className="flex items-center text-lg">
                  <span
                    className="rounded-full shadow-lg w-5 h-5 block mx-2"
                    style={{
                      background: getCustomerStatus.find(
                        (status) => status.id === 2
                      )?.color,
                    }}
                  ></span>
                  <span className="text-xs font-semibold">
                    {t(
                      getCustomerStatus.find((status) => status.id === 2)?.name
                    )}
                    &nbsp;:&nbsp;
                  </span>
                  <b>{customersStatistics?.pending || 0}</b>
                </span>
                <span className="flex items-center text-lg">
                  <span
                    className="rounded-full shadow-lg w-5 h-5 block mx-2"
                    style={{
                      background: getCustomerStatus.find(
                        (status) => status.id === 3
                      )?.color,
                    }}
                  ></span>
                  <span className="text-xs font-semibold">
                    {t(
                      getCustomerStatus.find((status) => status.id === 3)?.name
                    )}
                    &nbsp;:&nbsp;
                  </span>
                  <b>{customersStatistics?.actif || 0}</b>
                </span>
                {settingsData.company_type === "Services" && (
                  <span className="flex items-center text-lg">
                    <span
                      className="rounded-full shadow-lg w-5 h-5 block mx-2"
                      style={{
                        background: getCustomerStatus.find(
                          (status) => status.id === 4
                        )?.color,
                      }}
                    ></span>
                    <span className="text-xs font-semibold">
                      {t(
                        getCustomerStatus.find((status) => status.id === 4)
                          ?.name
                      )}
                      &nbsp;:&nbsp;
                    </span>
                    <b>{customersStatistics?.suspended || 0}</b>
                  </span>
                )}
              </div>
              <div className="w-1/5">
                <img
                  className="w-full h-auto"
                  src="/images/orders-icon.svg"
                  alt="Customers"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
          <div
            className={`${
              settingsData.company_type === "Products"
                ? "h-36 2xl:h-48"
                : "h-40 2xl:h-52"
            } relative cursor-pointer mx-4 py-6 px-4 rounded-sm shadow-md transition-all duration-800 bg-slate-100 hover:bg-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600`}
            onClick={() => navigate("/crm/my-projects")}
          >
            <span
              className="block text-xl font-bold mb-2"
              style={{ color: currentColor }}
            >
              {t("projects")}&nbsp;:&nbsp;{totalProjects || 0}
            </span>
            <div className="flex items-end">
              <div className="w-4/5">
                <span className="flex items-center text-lg">
                  <span
                    className="rounded-full shadow-lg w-5 h-5 block mx-2"
                    style={{
                      background: getOrderStatus.find(
                        (status) => status.id === 1
                      )?.color,
                    }}
                  ></span>
                  <span className="text-xs font-semibold">
                    {t(getOrderStatus.find((status) => status.id === 1)?.name)}
                    &nbsp;:&nbsp;
                  </span>
                  <b>{ordersStatistics?.pending || 0}</b>
                </span>
                <span className="flex items-center text-lg">
                  <span
                    className="rounded-full shadow-lg w-5 h-5 block mx-2"
                    style={{
                      background: getOrderStatus.find(
                        (status) => status.id === 2
                      )?.color,
                    }}
                  ></span>
                  <span className="text-xs font-semibold">
                    {t(getOrderStatus.find((status) => status.id === 2)?.name)}
                    &nbsp;:&nbsp;
                  </span>
                  <b>{ordersStatistics?.actif || 0}</b>
                </span>
                {settingsData.company_type === "Services" && (
                  <span className="flex items-center text-lg">
                    <span
                      className="rounded-full shadow-lg w-5 h-5 block mx-2"
                      style={{
                        background: getOrderStatus.find(
                          (status) => status.id === 4
                        )?.color,
                      }}
                    ></span>
                    <span className="text-xs font-semibold">
                      {t(
                        getOrderStatus.find((status) => status.id === 4)?.name
                      )}
                      &nbsp;:&nbsp;
                    </span>
                    <b>{ordersStatistics?.suspended || 0}</b>
                  </span>
                )}
              </div>
              <div className="w-1/5">
                <img
                  className="w-full h-auto"
                  src="/images/project-icon.svg"
                  alt="Project"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 w-full flex flex-col sm:flex-row">
        <div className="w-full sm:w-3/3 mb-4 sm:mb-0">
          <div className="home-block mx-4 py-6 px-4 rounded-sm shadow-md transition-all duration-920 bg-slate-100 hover:bg-slate-200 dark:bg-slate-500 dark:hover:bg-slate-600">
            <LineChart ordersStatistics={ordersStatistics} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RevenderHomePage;
