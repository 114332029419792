import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { MdAddShoppingCart } from 'react-icons/md';
import { useStateContext } from '../../../contexts/ContextProvider';
import NotAuthorized from '../../../components/NotAuthorized';
import Pagination from '../../../components/Pagination';
import { deleteEcommerceOrderRequest, getEcommerceOrdersRequest } from '../../../store/ecommerce/actions';
import moment from 'moment';
import { getEcommerceOrderStatus } from '../../../data/dummy';

const EOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders, pageCount, currentPage } = useSelector(state => state.ecommerce);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const { currentColor } = useStateContext();
  const { user } = useSelector(state => state.auth);
  const [payload, setPayload] = useState({
    status: 0,
    page: 0,
  });

  useEffect(() => {
    if (user?.role < 3) {
      dispatch(getEcommerceOrdersRequest(payload));
    }
  }, [payload, dispatch, user.role]);
    
  const handleDeleteUser = () => {
    dispatch(deleteEcommerceOrderRequest(selectedOrderId));
    setConfirmModal(false);
  }

  const onPageChange = (page) => {
    if (page.selected !== (currentPage + 1)) {
      setPayload({ ...payload, page: page.selected });
    }
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-main-dark-bg bg-white rounded-3xl">
      <div className="flex justify-between items-center">
        <p className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	" 
            style={{ color: currentColor }}>
              Orders
        </p>
        <MdAddShoppingCart className="text-3xl	cursor-cell"
                          style={{ color: currentColor }}
                          onClick={() => navigate("/ecommerce/orders/add")} />
      </div>
      <div className='flex justify-end items-center py-4'>
        <select className="bg-white dark:bg-main-dark-bg rounded-lg px-2 py-2 mr-2"
                style={{ color: currentColor }}
                onChange={(e) => setPayload({ ...payload, status: e.target.value })}>
          {getEcommerceOrderStatus.map((status, index) => (
            <option key={index} value={status.name}>{status.name}</option>
          ))}
        </select>
      </div>
      <table className='w-full rounded-md table-auto'>
        <thead className='border border-gray-100 bg-gray-100'>
          <tr>
            <th className='px-4 py-3 text-sm text-left'>Date</th>
            <th className='px-4 py-3 text-sm text-left'>Product</th>
            <th className='px-4 py-3 text-sm text-left'>Ref</th>
            <th className='px-4 py-3 text-sm'>Status</th>
            <th className='px-4 py-3 text-sm'>Price</th>
            <th className='px-4 py-3 text-sm'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders?.map(order => (
            <tr key={order._id} className="cursor-pointer transition-all duration-800 hover:bg-slate-100" onClick={() => navigate(`/ecommerce/orders/view/${order._id}`)} >
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{moment(order.date).format('DD/MM/YYYY')}</td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{order.product_name}</td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{order.product_ref}</td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>
                <span className={`block w-fit py-1 px-4 my-2 shadow-lg rounded-full text-white text-center mx-auto`} style={{ background: getEcommerceOrderStatus?.find(status => status.name = order.status)?.color || "#AAA" }}>{order.status}</span></td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white text-bold text-2xl text-center'>{order.sell_price}</td>
              <td className='border border-gray-100 px-4 py-2 flex h-full justify-center items-center'>
                <AiOutlineEdit className="mx-1 my-5 text-2xl text-green-500 hover:text-green-800 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/ecommerce/orders/edit/${order._id}`);
                                }} />
                <AiOutlineDelete className="mx-1 my-5 text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedOrderId(order._id);
                                  setConfirmModal(true);
                                }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
         pageCount={pageCount}
         onPageChange={(page) => onPageChange(page)}
        />

      <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${confirmModal ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className='text-lg text-bold mb-6'>Confirm Delete?</p>
          <div className="flex justify-end">
            <button className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md" onClick={() => setConfirmModal(false)}>
              Cancel
            </button>
            <button className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md" onClick={() => handleDeleteUser()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EOrders;
