import { axiosInstance } from "../../network";
//Projects
//Get Projects
export const getProjectsRequest = async (payload) => {
  return await axiosInstance.get(`api/projects`, { params: payload });
}
//Get Project
export const getProjectRequest = async (payload) => {
  return await axiosInstance.get(`api/projects/${payload}`);
}
//Suspend Project
export const suspendProjectRequest = async (id) => {
  return await axiosInstance.post(`api/projects/${id}/suspend`);
}
//Active Project
export const activeProjectRequest = async (id) => {
  return await axiosInstance.post(`api/projects/${id}/active`);
}
//Close Project
export const closeProjectRequest = async (id) => {
  return await axiosInstance.post(`api/projects/${id}/close`);
}
//Get My Projects
export const getMyProjectsRequest = async (payload) => {
  return await axiosInstance.get(`api/projects/my-projects`, { params: payload });
}

