import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { deleteUserRequest, getUsersRequest } from '../../store/users/actions';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users } = useSelector(state => state.users);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { currentColor } = useStateContext();
  const { user } = useSelector(state => state.auth);

  const roles = [
    { id: 1, name: 'Super Admin' },
    { id: 2, name: 'Admin' },
    { id: 3, name: 'Operator' },
    { id: 4, name: 'Commercial' },
    { id: 5, name: 'Employee' },
  ];

  useEffect(() => {
    dispatch(getUsersRequest());
  }, [dispatch]);
    
  const handleDeleteUser = () => {
    dispatch(deleteUserRequest(selectedUserId));
    setConfirmModal(false);
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-main-dark-bg bg-white rounded-3xl">
      <div className="flex justify-between items-center">
        <p className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	" 
            style={{ color: currentColor }}>
              Employees
        </p>
        <AiOutlineUserAdd className="text-3xl	cursor-cell"
                          style={{ color: currentColor }}
                          onClick={() => navigate("/hr/employees/add")} />
      </div>
      <div className='flex justify-end items-center py-4'>
        <input type="text" className="w-100 px-4 py-3 bg-gray-100 focus:outline-none" placeholder="Search By Name" />
      </div>
      <table className='w-full rounded-md table-auto'>
        <thead className='border border-gray-100 bg-gray-100'>
          <tr>
            <th className='px-4 py-3 text-sm'>Avatar</th>
            <th className='px-4 py-3 text-sm'>Name</th>
            <th className='px-4 py-3 text-sm'>Phone</th>
            <th className='px-4 py-3 text-sm'>Email</th>
            <th className='px-4 py-3 text-sm'>Role</th>
            <th className='px-4 py-3 text-sm'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users?.map(user => (
            <tr key={user._id}>
              <td className='border border-gray-100 px-4 py-2'>
                <img src={user.avatar} alt="Avatar" className='w-16 h-16 rounded-full'/>
              </td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{user.name}</td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{user.phone}</td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{user.email}</td>
              <td className='border border-gray-100 px-4 py-2 dark:text-white'>{roles?.find(item => item.id === user?.role)?.name}</td>
              <td className='border border-gray-100 px-4 py-2 flex h-full'>
                <AiOutlineEdit className="mx-1 my-5 text-2xl text-green-500 hover:text-green-800 cursor-pointer"
                                onClick={() => {
                                  navigate(`/hr/employees/edit/${user._id}`);
                                }} />
                <AiOutlineDelete className="mx-1 my-5 text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                                onClick={() => {
                                  setSelectedUserId(user._id);
                                  setConfirmModal(true);
                                }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${confirmModal ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className='text-lg text-bold mb-6'>Confirm Delete?</p>
          <div className="flex justify-end">
            <button className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md" onClick={() => setConfirmModal(false)}>
              Cancel
            </button>
            <button className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md" onClick={() => handleDeleteUser()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Employees;
