//PREORDERS
//Get Orders
export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_RECEIVE = "GET_ORDERS_RECEIVE";
//Get Order
export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_RECEIVE = "GET_ORDER_RECEIVE";
//Create Order
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_RECEIVE = "CREATE_ORDER_RECEIVE";
//Update Order
export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_RECEIVE = "UPDATE_ORDER_RECEIVE";
//Delete Order
export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_RECEIVE = "DELETE_ORDER_RECEIVE";
//Accept Order
export const ACCEPT_ORDER_REQUEST = "ACCEPT_ORDER_REQUEST";
export const ACCEPT_ORDER_RECEIVE = "ACCEPT_ORDER_RECEIVE";
//Reject Order
export const REJECT_ORDER_REQUEST = "REJECT_ORDER_REQUEST";
export const REJECT_ORDER_RECEIVE = "REJECT_ORDER_RECEIVE";
//List Customers
export const GET_LIST_CUSTOMERS_REQUEST = "GET_LIST_CUSTOMERS_REQUEST";
export const GET_LIST_CUSTOMERS_RECEIVE = "GET_LIST_CUSTOMERS_RECEIVE";
//Get Customer Orders
export const GET_CUSTOMER_ORDERS_REQUEST = "GET_CUSTOMER_ORDERS_REQUEST";
export const GET_CUSTOMER_ORDERS_RECEIVE = "GET_CUSTOMER_ORDERS_RECEIVE";
//Get My Orders
export const GET_MY_ORDERS_REQUEST = "GET_MY_ORDERS_REQUEST";
export const GET_MY_ORDERS_RECEIVE = "GET_MY_ORDERS_RECEIVE";
//Get Orders Statistics
export const GET_ORDERS_STATISTICS_REQUEST = "GET_ORDERS_STATISTICS_REQUEST";
export const GET_ORDERS_STATISTICS_RECEIVE = "GET_ORDERS_STATISTICS_RECEIVE";