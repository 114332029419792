//Suppliers
//Get Suppliers
export const GET_SUPPLIERS_REQUEST = "GET_SUPPLIERS_REQUEST";
export const GET_SUPPLIERS_RECEIVE = "GET_SUPPLIERS_RECEIVE";
//Get Supplier
export const GET_SUPPLIER_REQUEST = "GET_SUPPLIER_REQUEST";
export const GET_SUPPLIER_RECEIVE = "GET_SUPPLIER_RECEIVE";
//Create Supplier
export const CREATE_SUPPLIER_REQUEST = "CREATE_SUPPLIER_REQUEST";
export const CREATE_SUPPLIER_RECEIVE = "CREATE_SUPPLIER_RECEIVE";
//Update Supplier
export const UPDATE_SUPPLIER_REQUEST = "UPDATE_SUPPLIER_REQUEST";
export const UPDATE_SUPPLIER_RECEIVE = "UPDATE_SUPPLIER_RECEIVE";
//Delete Supplier
export const DELETE_SUPPLIER_REQUEST = "DELETE_SUPPLIER_REQUEST";
export const DELETE_SUPPLIER_RECEIVE = "DELETE_SUPPLIER_RECEIVE";