import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Ecommerce Orders
//Get Ecommerce Orders
export function* getEcommerceOrdersSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getEcommerceOrdersRequest, payload);
    yield put(ACTIONS.getEcommerceOrdersReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Ecommerce Order
export function* getEcommerceOrderSaga({ payload }) {
  try {
    const { data } = yield call(APIS.getEcommerceOrderRequest, payload);
    yield put(ACTIONS.getEcommerceOrderReceive(data));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Create Ecommerce Order
export function* createEcommerceOrderSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.createEcommerceOrderRequest, payload);
    yield put(ACTIONS.createEcommerceOrderReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/ecommerce/orders');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Update Ecommerce Order
export function* updateEcommerceOrderSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.updateEcommerceOrderRequest, payload);
    yield put(ACTIONS.updateEcommerceOrderReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/ecommerce/orders');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Delete Ecommerce Order
export function* deleteEcommerceOrderSaga({ id }) {
  try {
    const { data } = yield call(APIS.deleteEcommerceOrderRequest, id);
    yield put(ACTIONS.deleteEcommerceOrderReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* ecommerceSagas() {
  yield takeLatest(TYPES.GET_ECOMMERCE_ORDERS_REQUEST, getEcommerceOrdersSaga);
  yield takeLatest(TYPES.GET_ECOMMERCE_ORDER_REQUEST, getEcommerceOrderSaga);
  yield takeLatest(TYPES.CREATE_ECOMMERCE_ORDER_REQUEST, createEcommerceOrderSaga);
  yield takeLatest(TYPES.UPDATE_ECOMMERCE_ORDER_REQUEST, updateEcommerceOrderSaga);
  yield takeLatest(TYPES.DELETE_ECOMMERCE_ORDER_REQUEST, deleteEcommerceOrderSaga);
}


