import { axiosInstance } from "../../network";
//Suppliers
//Get Suppliers
export const getSuppliersRequest = async (payload) => {
  return await axiosInstance.get(`api/suppliers`, { params: payload });
}
//Get Supplier
export const getSupplierRequest = async (payload) => {
  return await axiosInstance.get(`api/suppliers/${payload}`);
}
//Create Supplier
export const createSupplierRequest = async (payload) => {
  return await axiosInstance.post(`api/suppliers`, payload);
} 
//Update Supplier
export const updateSupplierRequest = async (payload) => {
  return await axiosInstance.patch(`api/suppliers/${payload.id}`, payload);
}
//Delete Supplier
export const deleteSupplierRequest = async (id) => {
  return await axiosInstance.delete(`api/suppliers/${id}`);
}