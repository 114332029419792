//Get Customers
export const GET_CUSTOMERS_REQUEST = "GET_CUSTOMERS_REQUEST";
export const GET_CUSTOMERS_RECEIVE = "GET_CUSTOMERS_RECEIVE";
//Get Customer
export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_RECEIVE = "GET_CUSTOMER_RECEIVE";
//Add Customer
export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_RECEIVE = "ADD_CUSTOMER_RECEIVE";
//Edit Customer
export const EDIT_CUSTOMER_REQUEST = "EDIT_CUSTOMER_REQUEST";
export const EDIT_CUSTOMER_RECEIVE = "EDIT_CUSTOMER_RECEIVE";
//Delete Customer
export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_RECEIVE = "DELETE_CUSTOMER_RECEIVE";
//Get Customers Statistics
export const GET_CUSTOMERS_STATISTICS_REQUEST = "GET_CUSTOMERS_STATISTICS_REQUEST";
export const GET_CUSTOMERS_STATISTICS_RECEIVE = "GET_CUSTOMERS_STATISTICS_RECEIVE";
//Get Customers List
export const GET_CUSTOMERS_LIST_REQUEST = "GET_CUSTOMERS_LIST_REQUEST";
export const GET_CUSTOMERS_LIST_RECEIVE = "GET_CUSTOMERS_LIST_RECEIVE";
//Customer Change Status
export const CUSTOMER_CHANGE_STATUS_REQUEST = "CUSTOMER_CHANGE_STATUS_REQUEST";
export const CUSTOMER_CHANGE_STATUS_RECEIVE = "CUSTOMER_CHANGE_STATUS_RECEIVE";
//Unpaid Customers list
export const GET_UNPAID_CUSTOMERS_LIST_REQUEST = "GET_UNPAID_CUSTOMERS_LIST_REQUEST";
export const GET_UNPAID_CUSTOMERS_LIST_RECEIVE = "GET_UNPAID_CUSTOMERS_LIST_RECEIVE";
//Send custom mail
export const SEND_CUSTOM_MAIL_REQUEST = "SEND_CUSTOM_MAIL_REQUEST";
export const SEND_CUSTOM_MAIL_RECEIVE = "SEND_CUSTOM_MAIL_RECEIVE";
