import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useStateContext } from '../contexts/ContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { sendCustomMailRequest } from '../store/customers/actions';

function SendCustomMail(props) {
    const { email, showModal, closeModal } = props;
    const { currentColor } = useStateContext();
    const dispatch = useDispatch();
    const { mailIsSent } = useSelector(state => state.customers)
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        if (email) {
            setFormData({ ...formData, email });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    useEffect(() => {
        if (mailIsSent) {
            setTimeout(() => {
                closeModal()
            }, 500)
        }
    }, [mailIsSent])
    
    return (
        <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all duration-800 bg-black bg-opacity-20 ${showModal ? 'opacity-100 pointer-events-auto z-50' : 'opacity-0 pointer-events-none z-0'}`}>
            <div className="relative flex flex-col items-center justify-center p-8 rounded-lg bg-slate-100">
                <AiFillCloseCircle className='absolute right-5 top-5' style={{ '&:hover': { color: currentColor} }} size={30} onClick={closeModal} />
                <form>
                    <h3>Send Custom Email</h3>
                    <div className='mt-10'>
                        <label className='block text-sm font-bold mb-2'>To</label>
                        <input className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' 
                                type='email' value={formData.email} readOnly={email ? true : false}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                        <label className='block text-sm font-bold mb-2'>Subject</label>
                        <input className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                type='text' value={formData.subject}
                                onChange={(e) => setFormData({ ...formData, subject: e.target.value })} />
                        <label className='block text-sm font-bold mb-2'>Message</label>
                        <textarea className='shadow-md appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                value={formData.message}
                                onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                
                    </div>
                    <div className='flex justify-center items-center'>
                        <button className='bg-slate-200 hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                type='button' onClick={() => closeModal()}>
                            Cancel
                        </button>
                        <button className='ml-4 bg-slate-200 hover:bg-slate-300 dark:bg-slate-700 dark:hover:bg-slate-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                type='button' onClick={() => {
                                    dispatch(sendCustomMailRequest(formData))
                                }}>
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SendCustomMail