import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import NotAuthorized from "../../components/NotAuthorized";
import moment from "moment";
import {
  settingsData,
  getOrderStatus,
  getCustomerStatus,
} from "../../data/dummy";
import { MdArrowBack } from "react-icons/md";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { getOrderRequest } from "../../store/orders/actions";
import { Tooltip } from "@mui/material";
import { getServicesRequest } from "../../store/services/actions";
import { getProductsRequest } from "../../store/products/actions";
const OrderView = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.orders);
  const { services } = useSelector((state) => state.services);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (id) {
      dispatch(getOrderRequest(id));
      if (settingsData.company_type === "Services") {
        dispatch(getServicesRequest());
      } else if (settingsData.company_type === "Products") {
        dispatch(getProductsRequest());
      }
    }
  }, [dispatch, id]);

  if (user?.role > 7) {
    console.log("NotAuthorized");
    return <NotAuthorized />;
  }

  return (
    <div className="m-2 md:m-6 p-2 md:p-4 dark:bg-main-dark-bg bg-transparent">
      <div className="relative w-full mx-auto py-6 px-0 sm:px-4 mt-20 sm:mt-0">
        <div
          className="transition-all flex items-center duration-800 text-slate-700 dark:text-slate-200 hover:text-cyan-500 cursor-pointer mb-4 text-xl px-2 sm:px-4"
          onClick={() => navigate("/crm/orders")}
        >
          <MdArrowBack className="mr-2" /> Orders List
        </div>
        <div className="w-full flex flex-col sm:flex-row justify-between items-start">
          <div className="w-full sm:w-2/3 px-4">
            <div className="relative w-full p-4 mx-auto rounded-md shadow-md bg-slate-100 dark:bg-slate-600 mb-4 sm:mb-0 h-72">
              <span
                className="absolute top-4 text-slate-100 right-4 rounded-full py-2 px-4 shadow-md"
                style={{
                  background: getOrderStatus?.find(
                    (status) => status.id === order?.status
                  )?.color,
                }}
              >
                {
                  getOrderStatus?.find((status) => status.id === order?.status)
                    ?.name
                }
              </span>
              <h4 className="text-left text-sm text-bold mb-4 dark:text-slate-300">
                Order Infos
              </h4>
              <div className="text-sm flex items-center">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  Ref:{" "}
                </span>
                <span className="font-bold dark:text-white">
                  {`${settingsData.order_reference_prefix}`}
                  {order?.ref}
                </span>
              </div>
              <div className="text-sm flex items-center">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  Date:{" "}
                </span>
                <span className="font-bold dark:text-white">
                  {moment(order?.date).format("DD/MM/YYYY")}
                </span>
              </div>
              <div className="text-sm flex items-center">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  Project Name:{" "}
                </span>
                <span className="font-bold dark:text-white">
                  {order?.project?.name}
                </span>
              </div>
              <div className="text-sm flex items-center">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  Project Deadline:{" "}
                </span>
                <span className="font-bold dark:text-white">
                  {moment(order?.project?.deadline).format("DD/MM/YYYY")}
                </span>
              </div>
              <div className="w-full flex flex-nowrap justify-center items-center my-4 px-2 overflow-x-auto">
                <div className="w-26 flex-none mx-2 mb-3 text-sm rounded-md bg-slate-300 dark:bg-slate-700 shadow-md p-2">
                  <span className="block text-xs text-gray-800 dark:text-slate-200 mb-2">
                    Subtotal:{" "}
                  </span>
                  <span className="block text-xs text-center font-bold dark:text-white">
                    {order?.tax
                      ? order?.totalHT?.toFixed(3)
                      : order?.total?.toFixed(3)}{" "}
                    {settingsData.organization_currency}
                  </span>
                </div>
                {order?.tax && (
                  <>
                    <div className="w-26 flex-none mx-2 mb-3 text-sm rounded-md bg-slate-300 dark:bg-slate-700 shadow-md p-2">
                      <span className="block text-xs text-gray-800 dark:text-slate-200 mb-2">
                        Total Tax:{" "}
                      </span>
                      <span className="block text-xs text-center font-bold dark:text-white">
                        {order?.totalTax?.toFixed(3)}{" "}
                        {settingsData.organization_currency}
                      </span>
                    </div>
                    <div className="w-26 flex-none mx-2 mb-3 text-sm rounded-md bg-slate-300 dark:bg-slate-700 shadow-md p-2">
                      <span className="block text-xs text-gray-800 dark:text-slate-200 mb-2">
                        Total:{" "}
                      </span>
                      <span className="block text-xs text-center font-bold dark:text-white">
                        {order?.totalTTC?.toFixed(3)}{" "}
                        {settingsData.organization_currency}
                      </span>
                    </div>
                  </>
                )}
                <div className="w-26 flex-none mx-2 mb-3 text-sm rounded-md bg-slate-300 dark:bg-slate-700 shadow-md p-2">
                  <span className="block text-xs text-gray-800 dark:text-slate-200 mb-2">
                    Paid:{" "}
                  </span>
                  <span className="block text-xs text-center font-bold dark:text-white">
                    {order?.paid?.toFixed(3)}{" "}
                    {settingsData.organization_currency}
                  </span>
                </div>
                <div className="w-26 flex-none mx-2 mb-3 text-sm rounded-md bg-slate-300 dark:bg-slate-700 shadow-md p-2">
                  <span className="block text-xs text-gray-800 dark:text-slate-200 mb-2">
                    UnPaid:{" "}
                  </span>
                  <span className="block text-xs text-center font-bold dark:text-white">
                    {(order?.total - order?.paid)?.toFixed(3)}{" "}
                    {settingsData.organization_currency}
                  </span>
                </div>
              </div>
              {parseFloat(order.total) - parseFloat(order.paid) === 0 && (
                <img
                  src="/images/paid.png"
                  alt="paid"
                  className="w-8 h-8 rounded-full shadow-lg absolute left-2 bottom-2"
                />
              )}
              {order.status > 3 &&
                parseFloat(order.total) - parseFloat(order.paid) > 0 && (
                  <img
                    src="/images/no-money.png"
                    alt="paid"
                    className="w-8 h-8 rounded-full shadow-lg absolute left-2 bottom-2"
                  />
                )}
            </div>
          </div>
          <div className="w-full sm:w-1/3 px-4">
            <div className="w-full p-4 mx-auto rounded-md shadow-md bg-slate-100 dark:bg-slate-600 mb-4 sm:mb-0 h-72">
              <h4 className="text-center text-sm text-bold mb-4 dark:text-slate-300">
                Customer Infos
              </h4>
              <div className="relative">
                <img
                  src={order?.customer?.avatar || "/images/user.png"}
                  alt="customer"
                  className="block w-24 h-24 mx-auto border-2 border-slate-100 rounded-full object-cover mb-2"
                />
                <Tooltip
                  title={
                    getCustomerStatus?.find(
                      (status) => status.id === order?.customer?.status
                    )?.name
                  }
                >
                  <span
                    className="w-6 h-6 absolute bottom-0 right-1/3 text-slate-100 rounded-full shadow-md bg-slate-400"
                    style={{
                      background: getCustomerStatus?.find(
                        (status) => status.id === order?.customer?.status
                      )?.color,
                    }}
                  ></span>
                </Tooltip>
              </div>
              <div className="text-center dark:text-white font-bold mb-3">
                <span>{order?.customer?.name}</span>
              </div>
              <div className="text-sm flex items-center mb-1">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  <AiOutlineMail />
                </span>
                <span className="font-bold dark:text-white">
                  {order?.customer?.email}
                </span>
              </div>
              <div className="text-sm flex items-center mb-1">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  <AiOutlinePhone />
                </span>
                <span className="font-bold dark:text-white">
                  {order?.customer?.phone}
                </span>
              </div>
              <div className="text-sm flex items-center mb-1">
                <span className="text-gray-800 dark:text-slate-200 mr-2">
                  <GoLocation />
                </span>
                <span className="font-bold dark:text-white">
                  {order?.customer?.address}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-2">
          <div className="w-full p-4 mb-4">
            <h4 className="text-left text-sm text-bold my-4 dark:text-slate-300">
              Order Details
            </h4>
            <table className="w-full table-auto">
              <thead className="border border-gray-100 bg-gray-100">
                <tr>
                  <th className="text-left text-sm text-bold dark:text-slate-500 py-2 px-4">
                    Product
                  </th>
                  <th className="text-center text-sm text-bold dark:text-slate-500 py-2 px-4">
                    Quantity
                  </th>
                  <th className="hidden sm:table-cell text-left text-sm text-bold dark:text-slate-500 py-2 px-4">
                    Unit
                  </th>
                  <th className="hidden sm:table-cell text-center text-sm text-bold dark:text-slate-500 py-2 px-4">
                    Price
                  </th>
                  <th className="hidden sm:table-cell text-center text-sm text-bold dark:text-slate-500 py-2 px-4">
                    Discount
                  </th>
                  {order?.tax && (
                    <th className="hidden sm:table-cell text-center text-sm text-bold dark:text-slate-500 py-2 px-4">
                      Tax
                    </th>
                  )}
                  <th className="text-right text-sm text-bold dark:text-slate-500 py-2 px-4">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {order?.items?.map((item, index) => (
                  <tr key={index}>
                    <td className="text-left text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100">
                      {item.name}
                    </td>
                    <td className="text-center text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100 font-bold">
                      {item.quantity}
                    </td>
                    <td className="hidden sm:table-cell text-left text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100">
                      {settingsData.company_type === "Services"
                        ? services?.find(
                            (service) => service._id === item.serviceId
                          )?.unit
                        : products?.find(
                            (product) => product._id === item.productId
                          )?.unit}
                    </td>
                    <td className="hidden sm:table-cell text-center text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100">
                      <b>{item.price}</b> {settingsData.organization_currency}
                    </td>
                    <td className="hidden sm:table-cell text-center text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100">
                      <b>{item.discount}</b>%
                    </td>
                    {order?.tax && (
                      <td className="hidden sm:table-cell text-center text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100">
                        <b>
                          {settingsData.company_type === "Services"
                            ? services?.find(
                                (service) => service._id === item.serviceId
                              )?.tax
                            : products?.find(
                                (product) => product._id === item.productId
                              )?.tax}
                        </b>
                        %
                      </td>
                    )}
                    <td className="text-right text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100">
                      <b>{item.total}</b> {settingsData.organization_currency}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="px-2 w-full">
          <div className="w-full relative rounded-md p-4 mb-4 overflow-hidden">
            <h4 className="text-left text-sm text-bold mb-4 dark:text-slate-300">
              Order History
            </h4>
            <div
              className="w-full flex flex-nowrap justify-start items-center p-4 overflow-x-auto"
              style={{ maxWidth: "880px" }}
            >
              {order?.history?.map((history, index) => (
                <div
                  key={index}
                  className="w-32 flex-none p-3 shadow-md rounded-md mr-2 bg-slate-100 dark:bg-slate-600"
                >
                  <div className="w-full flex justify-center items-center font-bold mb-4 text-xs">
                    <span>
                      {moment(history.date).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </div>
                  <div className="flex justify-center items-center">
                    {history.action === "add" ? (
                      <img
                        src="/images/history/add-order.png"
                        alt="add-order"
                        className="block mx-auto mb-4 w-10 h-10 object-cover"
                      />
                    ) : history.action === "edit" ? (
                      <img
                        src="/images/history/edit-order.png"
                        alt="edit-order"
                        className="block mx-auto mb-4 w-10 h-10 object-cover"
                      />
                    ) : history.action === "payment" ? (
                      <img
                        src="/images/history/payment.png"
                        alt="payment"
                        className="block mx-auto mb-4 w-10 h-10 object-cover"
                      />
                    ) : history.action === "suspend" ? (
                      <img
                        src="/images/history/suspend.png"
                        alt="suspend"
                        className="block mx-auto mb-4 w-10 h-10 object-cover"
                      />
                    ) : history.action === "active" ? (
                      <img
                        src="/images/history/active.png"
                        alt="cancel"
                        className="block mx-auto mb-4 w-10 h-10 object-cover"
                      />
                    ) : (
                      <img
                        src="/images/history/completed.png"
                        alt="completed"
                        className="block mx-auto mb-4 w-10 h-10 object-cover"
                      />
                    )}
                  </div>
                  <div className="flex justify-center items-center mb-3">
                    <span
                      className="py-1 px-3 rounded-full text-xs text-white shadow-sm"
                      style={{
                        background: getOrderStatus?.find(
                          (status) => status.id === history?.nextStatus
                        )?.color,
                      }}
                    >
                      {
                        getOrderStatus?.find(
                          (status) => status.id === history?.nextStatus
                        )?.name
                      }
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderView;
