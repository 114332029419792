import * as TYPES from "./types";

const initialState = {
  suppliers: [],
  supplier: {},
  pageCount: 0,
  currentPage: 0,
  totalCount: 0,
};

export default function suppliersReducer(state = initialState, action) {
  switch (action.type) {
    //Get suppliers
    case TYPES.GET_SUPPLIERS_RECEIVE: {
      return {
        ...state,
        suppliers: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalCount: action.payload.headers["x-pagination-total"],
      }
    }
    //Get supplier
    case TYPES.GET_SUPPLIER_RECEIVE: {
      return {
        ...state,
        supplier: action.payload,
      }
    }
    //Create supplier
    case TYPES.CREATE_SUPPLIER_RECEIVE: {
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
      }
    }
    //Update supplier
    case TYPES.UPDATE_SUPPLIER_RECEIVE: {
      return {
        ...state,
        suppliers: state.suppliers.map(supplier => {
          if (supplier._id === action.payload._id) {
            return action.payload;
          }
          return supplier;
        }
        ),
      }
    }
    //Delete supplier
    case TYPES.DELETE_SUPPLIER_RECEIVE: {
      return {
        ...state,
        suppliers: state.suppliers.filter(supplier => supplier._id !== action.id),
      }
    }
    default:
      return state
  }  
}
