import React, { useEffect, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider';
import moment from 'moment';
import { acceptOrderRequest, rejectOrderRequest } from '../store/orders/actions';
import { useDispatch } from 'react-redux';
import { settingsData } from '../data/dummy';

function OrderAction({show, close, id, total}) {
    const dispatch = useDispatch();
    const { currentColor } = useStateContext();
    const [feedback, setFeedback] = useState('');
    const [formData, setFormData] = useState({
        id: null,
        status: 1,
        projectName: '',
        projectDeadline: '',
        projectNotes: '',
        rejectionReason: '',
        payment: '',
        paymentAmount: 0,
        paymentDate: '',
        paymentNotes: '',
        orderType: settingsData.company_type
    });

    const clearForm = () => {
        setFormData({
            id: null,
            status: 1,
            projectName: '',
            projectDeadline: '',
            projectNotes: '',
            rejectionReason: '',
            payment: '',
            paymentAmount: 0,
            paymentDate: '',
            paymentNotes: '',
            orderType: settingsData.company_type
        });
        setFeedback('');
    }

    useEffect(() => {
        if (id) {
            setFormData({
                id: id,
                status: 1,
                projectName: '',
                projectDeadline: '',
                projectNotes: '',
                rejectionReason: '',
                payment: '',
                paymentAmount: 0,
                paymentDate: '',
                paymentNotes: '',
                orderType: settingsData.company_type
            });
            setFeedback('');
        }
    }, [id, show]);              

    const validation = () => {
        let isValid = true;
        if (formData.payment) {
            if (formData.paymentAmount === 0) {
                setFeedback('Please enter a valid payment amount');
                isValid = false;
            }
            if (formData.paymentDate === '') {
                setFeedback('Please enter a valid payment date');
                isValid = false;
            }
            if (formData.paymentAmount > total) {
                setFeedback('Payment amount cannot be greater than total');
                isValid = false;
            }
           
        }
        if (formData.status === 2 && settingsData.company_type === "Services") {
            if (formData.projectDeadline === '') {
                setFeedback('Please enter a project deadline');
                isValid = false;
            }
            if (formData.projectName === '') {
                setFeedback('Please enter a project name');
                isValid = false;
            }
        }
        if (formData.status < 2) {
            setFeedback('Please select an action');
            isValid = false;
        }
        return isValid;
    }

    const handleSubmit = () => {
        setFeedback('');
        if (validation()) {
            if (formData.status === 2) {
                dispatch(acceptOrderRequest(formData));
            } else if (formData.status === 3) {
                dispatch(rejectOrderRequest(formData));
            }
            setTimeout(() => {
                clearForm();
                close();
            }, 1000);
        }
    }

    return (
        <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-40 ${show ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
            <div className="w-11/12 sm:w-3/5 py-6 px-10 shadow-xl rounded-md max-h-590 overflow-y-auto bg-slate-100 dark:bg-slate-500">
                <h3 className='text-2xl font-bold my-4 text-center' style={{ color: currentColor }}>Take Action</h3>
                <div className='confimrModalContent'>
                    <div className='my-6 flex justify-center px-2'>
                        <div className='mx-2'>
                            <img src="/images/correct.png" alt="Correct" 
                            className={`block shadow-md w-20 transition-all duration-1000 cursor-pointer border-2 border-transparent hover:border-green-700 rounded-full ${formData.status === 2 ? 'grayscale-0 opacity-100' : 'grayscale hover:grayscale-0 opacity-75 hover:opacity-90'}`}
                            onClick={() => {
                                setFormData({...formData, status: 2});
                                setFeedback('');
                            }}
                            />
                            <h6 className='mt-3 font-bold text-center'>Accept</h6>
                        </div>
                        <div className='mx-2'>
                            <img src="/images/failed.png" alt="failed" 
                                className={`shadow-md w-20 mx-2 transition-all duration-1000 cursor-pointer border-2 border-transparent hover:border-red-700 rounded-full ${formData.status === 3 ? 'grayscale-0' : 'grayscale hover:grayscale-0 opacity-75 hover:opacity-90'}`} 
                                onClick={() => {
                                    setFormData({ ...formData, status: 3 });
                                    setFeedback('');
                                }}
                                />
                            <h6 className='mt-3 font-bold text-center'>Reject</h6>
                        </div>  
                    </div>
                    { settingsData.company_type === "Services" && formData.status === 2 && <>
                        <div className='mt-6 flex w-full flex-col sm:flex-row'>
                            <div className='w-full sm:w-1/3 px-2 mb-4 sm:mb-0'>
                                <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Project Name <span className='text-red-600 ml-2'>*</span></label>
                                <input type='text' className='w-full py-3 px-4 bg-white focus:outline-none'
                                    value={formData.projectName}
                                    onChange={(e) => {
                                        setFormData({ ...formData, projectName: e.target.value });
                                        setFeedback('');
                                    }}
                                />
                            </div>
                            <div className='w-full sm:w-1/3 px-2'>
                                <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Deadline <span className='text-red-600 ml-2'>*</span></label>
                                <input type='date' className='w-full py-3 px-4 bg-white focus:outline-none' 
                                    value={formData.projectDeadline}
                                    onChange={(e) => {
                                        setFormData({ ...formData, projectDeadline: moment(e.target.value).format('YYYY-MM-DD') });
                                        setFeedback('');
                                    }}
                                />
                            </div>
                            <div className='w-full sm:w-1/3 mb-4 px-2'>
                                <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Project Notes</label>
                                <textarea className='w-full py-3 px-4 bg-white focus:outline-none'
                                    value={formData.projectNotes}
                                    onChange={(e) => {
                                        setFormData({ ...formData, projectNotes: e.target.value });
                                    }}
                                >
                                </textarea>
                            </div>
                        </div>
                    </>}
                    { formData.status === 3 && <>
                        <div className='mt-6 flex w-full mb-4'>
                            <div className='w-full px-2'>
                                <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Rejection Reason</label>
                                <textarea className='w-full py-3 px-4 bg-white focus:outline-none'
                                    value={formData.rejectionReason}
                                    onChange={(e) => {
                                        setFormData({ ...formData, rejectionReason: e.target.value });
                                    }}
                                >
                                </textarea>
                            </div>
                        </div>
                    </>}
                    <div className='mt-10 mb-4 flex justify-center items-center'>
                        <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md text-white font-bold focus:outline-none'
                                style={{ backgroundColor: currentColor }}
                                type='button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}>
                            Confirm
                        </button>
                        <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md bg-white border font-bold focus:outline-none'
                                style={{ borderColor: currentColor, color: currentColor }}
                                type='button'
                                onClick={(e) => {
                                    close();
                                }}>
                            Cancel
                        </button>
                    </div>
                    <p className='text-center w-full text-red-400 mt-4'>{feedback}</p>
                </div>
            </div>
        </div>
    )
}

export default OrderAction