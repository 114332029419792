import { all } from "redux-saga/effects";
import { authSagas } from "./auth/sagas";
import { usersSagas } from "./users/sagas";
import { notificationsSagas } from "./notifications/sagas";
import { eventsSagas } from "./events/sagas";
import { ecommerceSagas } from "./ecommerce/sagas";
import { customersSagas } from "./customers/sagas";
import { servicesSagas } from "./services/sagas";
import { ordersSagas } from "./orders/sagas";
import { projectsSagas } from "./projects/sagas";
import { paymentsSagas } from "./payments/sagas";
import { productsSagas } from "./products/sagas";
import { suppliersSagas } from "./suppliers/sagas";
import { purshacesSagas } from "./purshaces/sagas";
import { appointmentsSagas } from "./appointments/sagas";

export function* watchSagas() {
  yield all([
    authSagas(),
    usersSagas(),
    notificationsSagas(),
    eventsSagas(),
    ecommerceSagas(),
    customersSagas(),
    servicesSagas(),
    ordersSagas(),
    projectsSagas(),
    paymentsSagas(),
    productsSagas(),
    suppliersSagas(),
    purshacesSagas(),
    appointmentsSagas()
  ]);
}
