import * as TYPES from "./types";

const initialState = {
  purshaces: [],
  purshace: {},
  pageCount: 0,
  currentPage: 0,
  totalCount: 0,
};

export default function purshacesReducer(state = initialState, action) {
  switch (action.type) {
    //Get purshaces
    case TYPES.GET_PURSHACES_RECEIVE: {
      return {
        ...state,
        purshaces: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalCount: action.payload.headers["x-pagination-total"],
      }
    }
    //Get Product Purshaces
    case TYPES.GET_PRODUCT_PURSHACES_RECEIVE: {
      return {
        ...state,
        purshaces: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalCount: action.payload.headers["x-pagination-total"],
      }
    }
    //Get purshace
    case TYPES.GET_PURSHACE_RECEIVE: {
      return {
        ...state,
        purshace: action.payload,
      }
    }
    //Create purshace
    case TYPES.CREATE_PURSHACE_RECEIVE: {
      return {
        ...state,
        purshaces: [...state.purshaces, action.payload],
      }
    }
    //Update purshace
    case TYPES.UPDATE_PURSHACE_RECEIVE: {
      return {
        ...state,
        purshaces: state.purshaces.map(purshace => {
          if (purshace._id === action.payload._id) {
            return action.payload;
          }
          return purshace;
        }
        ),
      }
    }
    //Delete purshace
    case TYPES.DELETE_PURSHACE_RECEIVE: {
      return {
        ...state,
        purshaces: state.purshaces.filter(purshace => purshace._id !== action.id),
      }
    }
    default:
      return state
  }  
}
