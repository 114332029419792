import * as TYPES from "./types";
//Products
// Get Products
export const getProductsRequest = (payload) => ({
  type: TYPES.GET_PRODUCTS_REQUEST,
  payload,
});
export const getProductsReceive = (payload) => ({
  type: TYPES.GET_PRODUCTS_RECEIVE,
  payload,
});
//Get Product
export const getProductRequest = (payload) => ({
  type: TYPES.GET_PRODUCT_REQUEST,
  payload,
});
export const getProductReceive = (payload) => ({
  type: TYPES.GET_PRODUCT_RECEIVE,
  payload,
});
//Create Product
export const createProductRequest = (payload, navigate) => ({
  type: TYPES.CREATE_PRODUCT_REQUEST,
  payload,
  navigate,
});
export const createProductReceive = (payload) => ({
  type: TYPES.CREATE_PRODUCT_RECEIVE,
  payload,
});
//Update Product
export const updateProductRequest = (payload, navigate) => ({
  type: TYPES.UPDATE_PRODUCT_REQUEST,
  payload,
  navigate,
});
export const updateProductReceive = (payload) => ({
  type: TYPES.UPDATE_PRODUCT_RECEIVE,
  payload,
});
//Delete Product
export const deleteProductRequest = (id) => ({
  type: TYPES.DELETE_PRODUCT_REQUEST,
  id,
});
export const deleteProductReceive = (id) => ({
  type: TYPES.DELETE_PRODUCT_RECEIVE,
  id,
});
//Single Purshace
export const singlePurchaseRequest = (payload) => ({
  type: TYPES.SINGLE_PURCHASE_REQUEST,
  payload,
});
export const singlePurchaseReceive = (payload) => ({
  type: TYPES.SINGLE_PURCHASE_RECEIVE,
  payload,
});
//Multiple Purshace
export const multiplePurchaseRequest = (payload) => ({
  type: TYPES.MULTIPLE_PURCHASE_REQUEST,
  payload,
});