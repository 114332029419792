import React, { useEffect, useState } from 'react'
import { settingsData } from '../../data/dummy';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getServicesRequest } from '../../store/services/actions';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { BsGlobe2 } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { useParams } from 'react-router-dom';
import { getOrderRequest } from '../../store/orders/actions';
import { getProductsRequest } from '../../store/products/actions';

function OrderInvoice() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { order } = useSelector(state => state.orders);
    const { services } = useSelector(state => state.services);
    const { products } = useSelector(state => state.products);
    const [type, setType] = useState('');

    useEffect(() => {
        if (id) {
            dispatch(getOrderRequest(id))
            if (settingsData.company_type === "Services") {
              dispatch(getServicesRequest());
            } else if (settingsData.company_type === "Products") {
              dispatch(getProductsRequest());
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [dispatch, id])

    useEffect(() => {
        if (order) {
            setType(order?.status > 1 ? "Invoice" : "Quost Estimation");
        }
    } , [order])

    if (!id) { return null }

    console.log(order)

  return (
    <div className='p-10'>
        <div className='h-full flex flex-col items-between relative bg-white rounded-md p-10' >
              <div>
                <div className='flex justify-between items-center mb-16 mt-6'>
                  <div className='flex items-center'>
                    <img className='h-20 w-auto object-contain' src={settingsData.organization_logo} alt="Logo"/>
                    <h1 className='text-4xl ml-4'>{settingsData.organization_name}</h1>
                  </div>
                  <div>
                    {order?.qrCode && <img src={order?.qrCode} alt="QR Code" className='h-20 w-auto object-contain'/>}
                  </div>
                </div>
                <div className='flex justify-end mb-10'>
                  <h2 className='text-3xl font-bold'>{`${type}: `}{type === 'Invoice' ? settingsData.order_reference_prefix : settingsData.quost_reference_prefix}{order?.ref}</h2>
                </div>
                <div className='flex justify-between items-end mb-6'>
                  <div>
                    <p className='text-md mb-2'>Client: <strong>{order?.customer?.name}</strong></p>
                    <p className='text-md mb-2'>Phone: <strong>{order?.customer?.phone}</strong></p>
                    <p className='text-md mb-2'>Email: <strong>{order?.customer?.email}</strong></p>
                    <p className='text-md mb-2'>Address: <strong>{order?.customer?.address}</strong></p>
                  </div>
                  <div>
                    <p className='text-lg mb-2'><strong>{settingsData.organization_city}, {moment().format('DD/MM/YYYY')}</strong></p>
                  </div>
                </div>
                <div>
                  <table className='w-full rounded-md table-auto'>
                    <thead className='border border-gray-100 bg-gray-100'>
                      <tr>
                        <th className='px-2 sm:px-4 py-3 text-sm text-left'>Description</th>
                        <th className='px-2 sm:px-4 py-3 text-sm text-right'>Unit Price</th>
                        <th className='hidden sm:table-cell px-2 sm:px-4 py-3 text-sm text-center'>Quantity</th>
                        <th className='px-2 sm:px-4 py-3 text-sm text-left'>Unit</th>
                        <th className='px-2 sm:px-4 py-3 text-sm text-right'>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.items?.map(item => (
                        <tr key={item._id} >
                          <td className='text-sm border border-gray-100 px-2 sm:px-4 py-2 dark:text-slate-100 text-left'>
                            { settingsData.company_type === "Services" 
                                ? <span>{services?.find(service => service._id === item.serviceId)?.name} - {services?.find(service => service._id === item.serviceId)?.description}</span>
                                : <span>{products?.find(product => product._id === item.productId)?.name} - {products?.find(product => product._id === item.productId)?.description}</span>
                            }
                          </td>
                          <td className='hidden sm:table-cell whitespace-nowrap	border border-gray-100 px-2 sm:px-4 py-2 text-right text-sm font-bold'>
                            <b>{item.price.toFixed(3)}</b> {settingsData.organization_currency}
                          </td>
                          <td className='hidden sm:table-cell border border-gray-100 px-2 sm:px-4 py-2 text-center text-sm font-bold'>
                            {item.quantity}
                          </td>
                          <td className='hidden sm:table-cell border border-gray-100 px-2 sm:px-4 py-2 text-sm'>
                            { settingsData.company_type === "Services" 
                                ? <span>{services?.find(service => service._id === item.serviceId)?.unit}</span>
                                : <span>{products?.find(product => product._id === item.productId)?.unit}</span>
                            }
                          </td>
                          <td className='whitespace-nowrapborder border-gray-100 px-2 sm:px-4 py-2 text-right text-sm font-bold'><b>
                            {item.total?.toFixed(3)}</b> {settingsData.organization_currency}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='mt-4 w-full'>
                        <p className='w-full text-right'><span>Subtotal: </span><b>{order?.totalHT?.toFixed(3)}</b> {settingsData.organization_currency}</p>
                        <p className='w-full text-right'><span>Total Tax: </span><b>{order?.totalTax?.toFixed(3)}</b> {settingsData.organization_currency}</p>
                        <p className='w-full text-right'><span>Total: </span><b>{order?.totalTTC?.toFixed(3)}</b> {settingsData.organization_currency}</p>
                  </div>
              </div>

             {/*  <div className="my-10 flex justify-end items-center px-8">
                    <img src='/images/signature.png' alt="Signature" className='w-44 h-auto' />
              </div> */}
            
              <div className='w-full px-10 py-6 mt-20'>
                  <div className='w-full rounded-full py-2 px-6 flex justify-between items-center mb-2'>
                    <p className='flex items-center text-black text-xs'><GoLocation className='mr-2' /><span className='-mt-3'>{settingsData.organization_address}</span></p>
                    <p className='flex items-center text-black text-xs'><span className='-mt-3'>RIB: {settingsData.organization_rib}</span></p>
                    <p className='flex items-center text-black text-xs'><span className='-mt-3'>MF: {settingsData.fiscal_number}</span></p>
                  </div>
                  <div className='w-full rounded-full py-2 px-6 flex justify-between items-center bg-gradient-to-l from-rose-800 to-blue-800'>
                    <p className='flex items-center text-white text-xs'><AiOutlinePhone className='mr-2' /><span className='-mt-3'>{settingsData.organization_phone}</span></p>
                    <p className='flex items-center text-white text-xs'><BsGlobe2 className='mr-2' /><span className='-mt-3'>{settingsData.organization_website}</span></p>
                    <p className='flex items-center text-white text-xs'><AiOutlineMail className='mr-2' /><span className='-mt-3'>{settingsData.organization_email}</span></p>
                  </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default OrderInvoice