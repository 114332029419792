import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { RiUserAddLine } from 'react-icons/ri';
import { createSupplierRequest, getSupplierRequest, updateSupplierRequest } from '../../store/suppliers/actions';

const SupplierForm = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { supplier } = useSelector(state => state.suppliers);
  const { currentColor } = useStateContext();
  const [feedBack, setFeedBack] = useState('');
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    phone: '',
    email: '',
    address: '',
  });

  const clearForm = () => {
    setFormData({
      id: null,
      name: '',
      phone: '',
      email: '',
      address: '',
    });
  }

  useEffect(() => {
    if (id) {
      dispatch(getSupplierRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (supplier && id) {
      setFormData({
        id: supplier._id,
        name: supplier.name,
        phone: supplier.phone,
        email: supplier.email,
        address: supplier.address,
      });
    } else {
      clearForm();
    }
  }, [supplier, id]);

  const validation = () => {
    let isValid = true;
    if (formData.name.length === 0) {
      isValid = false;
      setFeedBack('Name is required');
    }
    return isValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFeedBack('');
    if (validation()) {
      if (id) {
        dispatch(updateSupplierRequest(formData, navigate));
      } else {
        dispatch(createSupplierRequest(formData, navigate));
      }
    }
  }

  if (user?.role > 4) {
    return <NotAuthorized />
  }
    
  return (
    <div className="m-2 md:m-6 p-2 md:p-4 dark:bg-main-dark-bg bg-transparent">
      <form className="relative w-full mx-auto py-6 px-4 sm:px-10 mt-20 sm:mt-0">
          <RiUserAddLine className='absolute left-2 top-2 opacity-10 text-slate-400 dark:text-slate-100 text-6xl sm:text-8xl' /> 
          <h3 className='mb-20 mx-auto text-center text-3xl font-bold dark:text-white text-slate-600' 
              style={{ color: currentColor }}>{id ? 'Edit' : 'Add'} Supplier</h3>
          <div className='flex flex-col sm:flex-row w-full mb-4'>
            <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
                <label htmlFor="name" className="text-sm dark:text-gray-200 text-gray-600 mb-2">Supplier Name<span className='text-red-600 ml-2'>*</span></label>
                <input id="name"
                        name="name"
                        type="text"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.name}
                        onChange={(event) => {
                          setFormData({...formData, name: event.target.value})
                          setFeedBack('')
                        }}
                />
            </div>
            <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
                <label htmlFor="phone" className="text-sm dark:text-gray-200 text-gray-600 mb-2">Phone</label>
                <input id="phone"
                        name="phone"
                        type="text"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.phone}
                        onChange={(event) => setFormData({...formData, phone: event.target.value})}
                />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row w-full mb-4'>
            <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
                <label htmlFor="email" className="text-sm dark:text-gray-200 text-gray-600 mb-2">Email</label>
                <input id="email"
                        name="email"
                        type="text"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.email}
                        onChange={(event) => setFormData({...formData, email: event.target.value})}
                />
            </div>
            <div className='w-full sm:w-1/2 px-4 mb-4 sm:mb-0'>
                <label htmlFor="address" className="text-sm dark:text-gray-200 text-gray-600 mb-2">Address</label>
                <input id="address"
                        name="address"
                        type="text"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.address}
                        onChange={(event) => setFormData({...formData, address: event.target.value})}
                />
            </div>
          </div>
          <div className='mt-10 mb-4 flex justify-center items-center'>
            <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md text-white font-bold focus:outline-none'
                    style={{ backgroundColor: currentColor }}
                    type='submit'
                    onClick={(e) => handleSubmit(e)}>
              {id ? 'Edit' : 'Add' } Supplier
            </button>
            <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md bg-white borderfont-bold focus:outline-none'
                    style={{ borderColor: currentColor, color: currentColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/crm/suppliers');
                    }}>
              Cancel
            </button>
          </div>
          <p className='text-center w-full text-red-400 mt-4'>{feedBack}</p>
      </form>
    </div>
  );
};
export default SupplierForm;
