import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../../contexts/ContextProvider';
import NotAuthorized from '../../../components/NotAuthorized';
import { getEcommerceOrderRequest, createEcommerceOrderRequest, updateEcommerceOrderRequest } from '../../../store/ecommerce/actions';
import moment from 'moment';
import { getSuppliersList, getShippersList } from '../../../data/dummy';
import { MdSwapHorizontalCircle, MdOutlineLocalShipping, MdCloudDone, MdOutlineCancel, MdOutlinePaid, MdOutlineAssignmentReturn } from 'react-icons/md';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { GrDropbox } from 'react-icons/gr';
const EOrderForm = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { order } = useSelector(state => state.ecommerce);
  const { currentColor } = useStateContext();
  const [formData, setFormData] = useState({
      id: null,
      date: moment().format('YYYY-MM-DD'),
      customer: "",
      city: "",
      status: "",
      product_name: "",
      product_ref: "",
      supplier: 1,
      buy_price: 0,
      sell_price: 0,
      shipper: 1,
      has_shipping_tax: true,
      is_collective_shipping: false,
      swap_breakage: false,
      returned: false,
      shipped: true,
      paid: false,
      completed: false,
      cancelled: false,
      shipper_payment: null,
      supplier_payment: null,
  });

  const clearForm = () => {
    setFormData({
      id: null,
      date: moment().format('YYYY-MM-DD'),
      customer: "",
      city: "",
      status: "",
      product_name: "",
      product_ref: "",
      supplier: 1,
      buy_price: 0,
      sell_price: 0,
      shipper: 1,
      has_shipping_tax: true,
      is_collective_shipping: false,
      swap_breakage: false,
      returned: false,
      shipped: true,
      paid: false,
      completed: false,
      cancelled: false,
      shipper_payment: null,
      supplier_payment: null,
    });
  }

  useEffect(() => {
    if (id) {
      dispatch(getEcommerceOrderRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (order && id) {
      setFormData({
        id: null,
        date: moment(order.date).format("YYYY-MM-DD"),
        customer: order.customer,
        city: order.city,
        status: order.status,
        product_name: order.product_name,
        product_ref: order.product_ref,
        supplier: order.supplier,
        buy_price: order.buy_price,
        sell_price: order.sell_price,
        shipper: order.shipper,
        has_shipping_tax: order.has_shipping_tax,
        is_collective_shipping: order.is_collective_shipping,
        swap_breakage: order.swap_breakage,
        returned: order.returned,
        shipped: order.shipped,
        paid: order.paid,
        completed: order.completed,
        cancelled: order.cancelled,
        shipper_payment: order.shipper_payment,
        supplier_payment: order.supplier_payment,
      });
    } else {
      clearForm();
    }
  }, [order, id]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      dispatch(updateEcommerceOrderRequest(formData, navigate));
    } else {
      dispatch(createEcommerceOrderRequest(formData, navigate));
    }
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }
    
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-main-dark-bg bg-transparent rounded-3xl">
      <form  className="w-full mx-auto py-10 px-20 bg-slate-100 bg-gradient-to-tr from-slate-100 to-slate-400 rounded-md dark:from-slate-500 dark:to-slate-700">
          <h3 className='mb-10 mx-auto text-center text-3xl font-bold dark:text-white text-slate-600' 
              style={{ color: currentColor }}>{id ? 'Edit' : 'Add'} Order</h3>
          <div className='block w-full'>
            <div className='flex w-full mb-4'>
              <div className='w-1/3 px-2'>
                <label htmlFor="date" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Date</label>
                <input id="date"
                        name="date"
                        type="date"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.date}
                        onChange={(event) => setFormData({...formData, date: moment(event.target.value).format('YYYY-MM-DD')})}
                />
              </div>
              <div className='w-1/3 px-2'>
                <label htmlFor="customer" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Customer</label>
                <input id="customer"
                        name="customer"
                        type="text"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.customer}
                        onChange={(event) => setFormData({...formData, customer: event.target.value})}
                />
              </div>
              <div className='w-1/3 px-2'>
                <label htmlFor="city" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">City</label>
                <input id="city"
                        name="city"
                        type="text"
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.city}
                        onChange={(event) => setFormData({...formData, city: event.target.value})}
                />
              </div>
            </div>
            <div className='flex w-full mb-4'>
              <div className='w-1/3 px-2'>
                <label htmlFor="product_name" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Product Name</label>
                <input id="product_name"
                        name="product_name"
                        type="text"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.product_name}
                        onChange={(event) => setFormData({...formData, product_name: event.target.value})}
                />
              </div>
              <div className='w-1/3 px-2'>
                <label htmlFor="product_ref" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Product Ref</label>
                <input id="product_ref"
                        name="product_ref"
                        type="text"
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.product_ref}
                        onChange={(event) => setFormData({...formData, product_ref: event.target.value})}
                />
              </div>
              <div className='w-1/3 px-2'>
                <label htmlFor="supplier" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Supplier</label>    
                <select id="supplier"
                        name="supplier"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.supplier}
                        onChange={(event) => setFormData({...formData, supplier: event.target.value})}
                >
                  <option value="">Select Supplier</option>
                  {getSuppliersList?.map((supplier) => (
                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='flex w-full mb-4'>
              <div className='w-1/3 px-2'>
                <label htmlFor="buy_price" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Buy Price</label>
                <input id="buy_price"
                        name="buy_price"
                        type="text"
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.buy_price}
                        onChange={(event) => setFormData({...formData, buy_price: event.target.value})}
                />
              </div>
              <div className='w-1/3 px-2'>
                <label htmlFor="sell_price" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Sell Price</label>
                <input id="sell_price"
                        name="sell_price"
                        type="text"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.sell_price}
                        onChange={(event) => setFormData({...formData, sell_price: event.target.value})}
                />
              </div>
              <div className='w-1/3 px-2'>
                <label htmlFor="shipper" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Shipper</label>    
                <select id="shipper"
                        name="shipper"
                        required
                        className='w-full py-3 px-6 rounded-md bg-white focus:outline-none disabled:bg-gray-200 transition-all duration-800'
                        disabled={formData.cancelled}
                        value={formData.shipper}
                        onChange={(event) => setFormData({...formData, shipper: event.target.value})}
                >
                  <option value="">Select Shipper</option>
                  {getShippersList?.map((shipper) => (
                    <option key={shipper.id} value={shipper.id}>{shipper.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='flex justify-start w-full mb-4'>
              <div className='w-1/3 px-2'>
                <div className='flex justify-start items-center cursor-pointer '
                      onClick={() => !formData.cancelled && setFormData({...formData, has_shipping_tax: !formData.has_shipping_tax})}>
                    <div className={`flex flex-col justify-center items-center w-10 h-10 mx-2 transition-all duration-700 border-2 rounded-full opacity-80 hover:opacity-100 ${formData.has_shipping_tax ? "bg-pink-400 border-pink-500 hover:border-pink-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}>
                        <FaMoneyBillAlt className='text-white text-xl' />
                    </div>
                    <span className='mt-2 text-md text-bold'>Has Shipping Tax</span>
                </div>
              </div>
              <div className='w-1/3 px-2'>
                <div className='flex justify-start items-center cursor-pointer '
                      onClick={() => !formData.cancelled && setFormData({...formData, is_collective_shipping: !formData.is_collective_shipping})}>
                    <div className={`flex flex-col justify-center items-center w-10 h-10 mx-2 transition-all duration-700 border-2 rounded-full opacity-80 hover:opacity-100 ${formData.is_collective_shipping ? "bg-purple-400 border-purple-500 hover:border-purple-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}>
                        <GrDropbox className='text-white text-xl' />
                    </div>
                    <span className='mt-2 text-md text-bold'>Collective shipping</span>
                </div>
              </div>
              <div className='w-1/3 px-2'>
                <div className='flex justify-start items-center cursor-pointer '
                      onClick={() => !formData.cancelled && setFormData({...formData, swap_breakage: !formData.swap_breakage})}>
                    <div className={`flex flex-col justify-center items-center w-10 h-10 mx-2 transition-all duration-700 border-2 rounded-full opacity-80 hover:opacity-100 ${formData.swap_breakage ? "bg-teal-400 border-teal-500 hover:border-teal-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}>
                        <MdSwapHorizontalCircle className='text-white text-xl' />
                    </div>
                    <span className='mt-2 text-md text-bold'>Swap breakage</span>
                </div>
              </div>
            </div>
            
            <div className='flex w-full mb-4'>
              <div className='w-1/5 px-2 py-4'>
                <div className={`cursor-pointer flex flex-col justify-center items-center w-28 h-28 mx-auto transition-all duration-700 border-2 rounded-md opacity-80 hover:opacity-100 ${formData.returned ? "bg-orange-400 border-orange-500 hover:border-orange-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}
                    onClick={() => !formData.cancelled && setFormData({...formData, returned: !formData.returned, shipped: false, paid: false, completed: false})} >
                    <MdOutlineAssignmentReturn className='text-white text-3xl' />
                    <span className='mt-2 text-sm text-bold'>Returned</span>
                </div>
              </div>
              <div className='w-1/5 px-2 py-4'>
                <div className={`cursor-pointer flex flex-col justify-center items-center w-28 h-28 mx-auto transition-all duration-700 border-2 rounded-md opacity-80 hover:opacity-100 ${formData.shipped ? "bg-blue-400 border-blue-500 hover:border-blue-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}
                    onClick={() => !formData.cancelled && !formData.returned && setFormData({...formData, shipped: !formData.shipped})}
                >
                    <MdOutlineLocalShipping  className='text-white text-3xl'  />
                    <span className='mt-2 text-sm text-bold'>Shipped</span>
                </div>
              </div>
              <div className='w-1/5 px-2 py-4'>
                <div className={`cursor-pointer flex flex-col justify-center items-center w-28 h-28 mx-auto transition-all duration-700 border-2 rounded-md opacity-80 hover:opacity-100 ${formData.paid ? "bg-cyan-400 border-cyan-500 hover:border-cyan-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}
                    onClick={() => !formData.cancelled && !formData.returned && setFormData({...formData, paid: !formData.paid})}
                >
                    <MdOutlinePaid  className='text-white text-3xl'  />
                    <span className='mt-2 text-sm text-bold'>Paid</span>
                </div>
              </div>
              <div className='w-1/5 px-2 py-4'>
                <div className={`cursor-pointer flex flex-col justify-center items-center w-28 h-28 mx-auto transition-all duration-700 border-2 rounded-md opacity-80 hover:opacity-100 ${formData.completed ? "bg-green-400 border-green-500 hover:border-green-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}
                    onClick={() => !formData.cancelled && !formData.returned && setFormData({...formData, completed: !formData.completed})}
                >
                    <MdCloudDone  className='text-white text-3xl'  />
                    <span className='mt-2 text-sm text-bold'>Completed</span>
                </div>
              </div>
              <div className='w-1/5 px-2 py-4'>
                <div className={`cursor-pointer flex flex-col justify-center items-center w-28 h-28 mx-auto transition-all duration-700 border-2 rounded-md opacity-80 hover:opacity-100 ${formData.cancelled ? "bg-red-400 border-red-500 hover:border-red-600 shadow-xl" : "bg-gray-400 border-gray-500 hover:border-gray-600 shadow-sm"}`}
                    onClick={() => setFormData({...formData, cancelled: !formData.cancelled})}
                >
                    <MdOutlineCancel  className='text-white text-3xl'  />
                    <span className='mt-2 text-sm text-bold'>Cancelled</span>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-10 mb-4 flex justify-center items-center'>
            <button className='w-48 mx-3 py-3 px-6 rounded-md text-white font-bold focus:outline-none'
                    style={{ backgroundColor: currentColor }}
                    type='submit'
                    onClick={(e) => handleSubmit(e)}>
              {id ? 'Edit' : 'Add' } Order
            </button>
            <button className='w-48 mx-3 py-3 px-6 rounded-md bg-white borderfont-bold focus:outline-none'
                    style={{ borderColor: currentColor, color: currentColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/ecommerce/orders');
                    }}>
              Cancel
            </button>
          </div>
      </form>
    </div>
  );
};
export default EOrderForm;
