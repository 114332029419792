import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { addUserRequest, editUserRequest, getUserDataRequest } from '../../store/users/actions';
import { useParams } from 'react-router';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { getUserRoles } from '../../data/dummy';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { Autocomplete } from '@mui/material';
import { getListCustomersRequest } from '../../store/orders/actions';
import { useTranslation } from 'react-i18next';

const UserForm = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector(state => state.auth);
  const { userData } = useSelector(state => state.users);
  const { listCustomers } = useSelector(state => state.orders);
  const [inputValue, setInputValue] = useState('');
  const [customer, setCustomer] = useState(null);
  const { currentColor } = useStateContext();
  const [feedBack, setFeedBack] = useState('');
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    phone: '',
    email: '',
    password: '',
    role: 2,
    avatar: '',
    is_new_customer: true,
    customer_id: '',
    is_actif: true,
  });

  useEffect(() => {
    if (id) {
      dispatch(getUserDataRequest(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getListCustomersRequest());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData && id) {
      setFormData({
        id: userData._id,
        name: userData.name,
        phone: userData.phone,
        email: userData.email,
        password: userData.password,
        role: userData.role,
        avatar: userData.avatar,
        customer_id: userData.custumer_id,
        is_new_customer: userData.is_new_customer,
        is_actif: userData.is_actif,
      });
    } else {
      setFormData({
        id: null,
        name: '',
        phone: '',
        email: '',
        password: '',
        role: 2,
        avatar: '',
        is_new_customer: true,
        customer_id: '',
        is_actif: true,
      });
    }
  }, [userData, id]);

  useEffect(() => {
    if (location) {
      setCustomer(location?.search?.split('=')[1]?.toString());
    }
  }, [location]);

  useEffect(() => {
    if (listCustomers && customer && customer !== '') {
      setFormData({ ...formData, customer_id: listCustomers?.find(cus => cus._id === customer)?._id });
      setInputValue(listCustomers?.find(cus => cus._id === customer)?.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, listCustomers]);

  const validation = () => {
    let isValid = true;
    if (formData.name === '') {
      setFeedBack('Please enter a name');
      isValid = false;
    }
    if (formData.phone === '') {
      setFeedBack('Please enter a phone number');
      isValid = false;
    }
    if (formData.email === '') {
      setFeedBack('Please enter an email');
      isValid = false;
    }
    if (formData.password === '' && !id) {
      setFeedBack('Please enter a password');
      isValid = false;
    }
    return isValid;
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      if (id) {
        dispatch(editUserRequest(formData, navigate));
      } else {
        dispatch(addUserRequest(formData, navigate));
      }
    }
  }

  if (user?.role !== 1) {
    return <NotAuthorized />
  }

  console.log('formData', formData);
    
  return (
    <div className="m-2 md:m-6 p-2 md:p-4 dark:bg-main-dark-bg bg-transparent">
      <form  className="relative w-full mx-auto py-6 px-4 sm:px-10 mt-20 sm:mt-0">
        <AiOutlineUsergroupAdd className='absolute left-2 top-2 opacity-10 text-slate-400 dark:text-slate-100 text-6xl sm:text-8xl' /> 
        <h3 className='mb-20 mx-auto text-center text-3xl font-bold dark:text-white text-slate-600' 
              style={{ color: currentColor }}>{id ? 'Edit' : 'Add'} User</h3>
        <div className='flex w-full flex-wrap mb-0 sm:mb-4'>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="name" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">User name<span className='text-red-600 ml-2'>*</span></label>
                <input id="name"
                        name="name"
                        type="text"
                        required
                        autoComplete="false"
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.name}
                        onChange={(event) => setFormData({...formData, name: event.target.value})}
                />
            </div>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="phone" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Phone Number<span className='text-red-600 ml-2'>*</span></label>
                <input id="phone"
                        name="phone"
                        type="tel"
                        required
                        autoComplete="false"
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.phone}
                        onChange={(event) => setFormData({...formData, phone: event.target.value})}
                />
            </div>
        </div>
        <div className='flex w-full flex-wrap mb-0 sm:mb-4'>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="email" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Email address<span className='text-red-600 ml-2'>*</span></label>
                <input id="email"
                        name="email"
                        type="email"
                        required
                        autoComplete="false"
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.email}
                        onChange={(event) => setFormData({...formData, email: event.target.value})}
                />
            </div>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="password" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Password{!id && <span className='text-red-600 ml-2'>*</span>}</label>
                <input id="password"
                        name="password"
                        type="password"
                        required
                        autoComplete="false"
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.password}
                        onChange={(event) => setFormData({...formData, password: event.target.value})}
                />
            </div>
        </div>
        <div className='flex w-full flex-wrap mb-0 sm:mb-4'>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="role" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Role</label>
                <select id="role"
                        name="role"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.role}
                        onChange={(event) => setFormData({...formData, role: event.target.value})}
                >
                  {
                    getUserRoles?.map(role => {
                      if (role > 2) {
                        return <option key={role.id} value={role.id}>{role.name}</option>
                      } else {
                        if (user?.role === 1) {
                          return <option key={role.id} value={role.id}>{role.name}</option>
                        } else { 
                          return null 
                        }
                      }
                    })
                  }
                </select>
            </div>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="is_actif" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Is Actif</label>
                <select id="is_actif"
                        name="is_actif"
                        required
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.is_actif}
                        onChange={(event) => setFormData({...formData, is_actif: event.target.value})}
                >
                  <option value={true}>Oui</option>
                  <option value={false}>Non</option>
                </select>
            </div>
        </div>
        <div className='flex w-full flex-wrap mb-0 sm:mb-4'>
            <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                <label htmlFor="avatar" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">Avatar URL</label>
                <input id="avatar"
                        name="avatar"
                        type="text"
                        className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                        value={formData.avatar}
                        onChange={(event) => setFormData({...formData, avatar: event.target.value})}
                />
            </div>
            {
              parseInt(formData.role) === 6 && (
                <div className='w-full sm:w-1/2 px-2 mb-4 sm:mb-0'>
                    <div className="flex w-full flex-wrap mb-0 p-4">
                      <div className="flex items-center">
                          <input 
                              id="is_new_customer-1"
                              type="radio"
                              defaultChecked
                              value={true}
                              onChange={() => setFormData({ ...formData, is_new_customer: true, customer_id: null })}
                              name="is_new_customer"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label for="is_new_customer-1" class="ms-2 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nouveau Client</label>
                      </div>
                      <div className="flex items-center">
                          <input 
                              id="is_new_customer-2"
                              type="radio"
                              value={false}
                              onChange={() => setFormData({ ...formData, is_new_customer: false })}
                              name="is_new_customer"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label for="is_new_customer-2" class="ms-2 mx-2 text-sm font-medium text-gray-900 dark:text-gray-300">Client existant</label>
                      </div>
                    </div>
                    {
                      formData.is_new_customer === false && (
                        <div className='w-full px-2 mb-4 sm:mb-0'>
                          <label htmlFor="customer" className="block text-sm dark:text-gray-200 text-gray-600 mb-2">{t("customer")} <span className='text-red-600 ml-2'>*</span></label>
                          <Autocomplete
                              id="customer-auto"
                              value={formData.customer_id}
                              onChange={(event, newValue) => {
                                setFormData({...formData, customer_id: newValue._id});
                              }}
                              inputValue={inputValue}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              options={listCustomers}
                              sx={{ width: "100%" }}
                              isOptionEqualToValue={(option) => option._id === formData.customer_id}
                              getOptionLabel={(option) => option.name || inputValue}
                              renderInput={(params) => (
                                  <div ref={params.InputProps.ref}>
                                      <input type="text" 
                                          id="customer" 
                                          name="customer" 
                                          placeholder={t('search_customer')}
                                          className='w-full py-3 px-4 bg-slate-100 focus:outline-none rounded-none'
                                          {...params.inputProps} 
                                        />
                                  </div>
                              )}
                            />
                        </div>
                      )
                    }
                </div>
              )}
        </div>
        <div className='mt-10 mb-4 flex justify-center items-center'>
            <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md text-white font-bold focus:outline-none'
                    style={{ backgroundColor: currentColor }}
                    type='submit'
                    onClick={(e) => handleSubmit(e)}>
              {id ? 'Edit' : 'Add' } User
            </button>
            <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md bg-white borderfont-bold focus:outline-none'
                    style={{ borderColor: currentColor, color: currentColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/users');
                    }}>
              Cancel
            </button>
          </div>
          <p className='text-center w-full text-red-400 mt-4'>{feedBack}</p>
      </form>
    </div>
  );
};
export default UserForm;
