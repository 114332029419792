import React from 'react';

//import { pieChartData } from '../../data/dummy';
import { Pie as PieChart } from '../../components';

const Pie = ({pieChartData}) => (
  <div className="w-full">
    <div className="w-full p-2">
      <PieChart id="chart-pie" data={pieChartData} legendVisiblity={false} height="300" />
    </div>
  </div>
);

export default Pie;
