import * as TYPES from "./types";
//Purshaces
// Get Purshaces
export const getPurshacesRequest = (payload) => ({
  type: TYPES.GET_PURSHACES_REQUEST,
  payload,
});
export const getPurshacesReceive = (payload) => ({
  type: TYPES.GET_PURSHACES_RECEIVE,
  payload,
});
// Get Product Purshaces
export const getProductPurshacesRequest = (payload) => ({
  type: TYPES.GET_PRODUCT_PURSHACES_REQUEST,
  payload,
});
export const getProductPurshacesReceive = (payload) => ({
  type: TYPES.GET_PRODUCT_PURSHACES_RECEIVE,
  payload,
});
//Get Purshace
export const getPurshaceRequest = (payload) => ({
  type: TYPES.GET_PURSHACE_REQUEST,
  payload,
});
export const getPurshaceReceive = (payload) => ({
  type: TYPES.GET_PURSHACE_RECEIVE,
  payload,
});
//Create Purshace
export const createPurshaceRequest = (payload, navigate) => ({
  type: TYPES.CREATE_PURSHACE_REQUEST,
  payload,
  navigate,
});
export const createPurshaceReceive = (payload) => ({
  type: TYPES.CREATE_PURSHACE_RECEIVE,
  payload,
});
//Update Purshace
export const updatePurshaceRequest = (payload, navigate) => ({
  type: TYPES.UPDATE_PURSHACE_REQUEST,
  payload,
  navigate,
});
export const updatePurshaceReceive = (payload) => ({
  type: TYPES.UPDATE_PURSHACE_RECEIVE,
  payload,
});
//Delete Purshace
export const deletePurshaceRequest = (id) => ({
  type: TYPES.DELETE_PURSHACE_REQUEST,
  id,
});
export const deletePurshaceReceive = (id) => ({
  type: TYPES.DELETE_PURSHACE_RECEIVE,
  id,
});