import { axiosInstance } from "../../network";
//Products
//Get Products
export const getProductsRequest = async (payload) => {
  return await axiosInstance.get(`api/products`, { params: payload });
}
//Get Product
export const getProductRequest = async (payload) => {
  return await axiosInstance.get(`api/products/${payload}`);
}
//Create Product
export const createProductRequest = async (payload) => {
  return await axiosInstance.post(`api/products`, payload);
} 
//Update Product
export const updateProductRequest = async (payload) => {
  return await axiosInstance.patch(`api/products/${payload.id}`, payload);
}
//Delete Product
export const deleteProductRequest = async (id) => {
  return await axiosInstance.delete(`api/products/${id}`);
}
//Single Purshace
export const singlePurchaseRequest = async (payload) => {
  return await axiosInstance.post(`api/products/singlePurchase`, payload);
}
//Multiple Purshace
export const multiplePurchaseRequest = async (payload) => {
  return await axiosInstance.post(`api/products/multiplePurchase`, payload);
}