export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RECEIVE = "LOGIN_RECEIVE";

export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_RECEIVE = "FORGET_PASSWORD_RECEIVE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVE = "RESET_PASSWORD_RECEIVE";

export const FORCE_PASSWORD_REQUEST = "FORCE_PASSWORD_REQUEST";
export const FORCE_PASSWORD_RECEIVE = "FORCE_PASSWORD_RECEIVE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_RECEIVE = "GET_USER_RECEIVE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_RECEIVE = "LOGOUT_RECEIVE";

export const GET_MY_STATISTICS_REQUEST = "GET_MY_STATISTICS_REQUEST";
export const GET_MY_STATISTICS_RECEIVE = "GET_MY_STATISTICS_RECEIVE";
