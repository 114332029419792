import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordRequest } from '../store/auth/actions';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const { token } = useParams();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(resetPasswordRequest({email: localStorage.getItem('email'), password}));
    setTimeout(() => {
        navigate('/')
    }, 1000);
  }
  
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      {
        token ? (
          <div className="max-w-md w-full space-y-8">
            <div>
              <img className="mx-auto h-16 w-auto" src="/logo.png" alt="Workflow" />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Veuillez entrer votre nouveau mot de passe?</h2>
            </div>
            <form className="mt-8 space-y-6">
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="password" className="sr-only">Nouveau mot de passe</label>
                  <input id="password" 
                        name="password" 
                        type="password" 
                        autoComplete="password" 
                        required 
                        className="my-2 appearance-none rounded-md relative block w-full px-6 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                        placeholder="Nouveau mot de passe"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        />
                </div>
              </div>          
              <div>
                <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={(e) => handleSubmit(e)}>
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                    </svg>
                  </span>
                  Réinitialiser le mot de passe
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className='flex justify-center items-center mt-20 text-2xl'>
            No Token
          </div>
        )
      }
    </div>
  );
}

export default ResetPassword;