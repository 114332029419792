import React, { useEffect, useState } from 'react'
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { getCustomerRatingRange, kanbanGrid } from '../../data/dummy';
import { useDispatch, useSelector } from 'react-redux/';
import { getCustomersListRequest } from '../../store/customers/actions';
import NotAuthorized from '../../components/NotAuthorized';
import { getCustomerStatus } from '../../data/dummy';
import { Rating, Tooltip } from '@mui/material';
import { settingsData } from '../../data/dummy';
import { useNavigate } from 'react-router-dom';
import { BiMailSend } from 'react-icons/bi';
import SendCustomMail from '../../components/SendCustomMail';
import { MdAddShoppingCart } from 'react-icons/md';

const columnTemplate = (props) => {
    return (
        <div key={props.headerText} className="flex justify-between items-center px-4 py-3 mb-2">
            <div className="flex items-center">
                {kanbanGrid.find(item => item.keyField === props.keyField)?.icon}
                <div className="px-2">
                    <span className="text-md font-bold text-gray-600 mr-3">{props.count}</span>
                    <span className="text-md font-bold" 
                        style={{ color: kanbanGrid.find(item => item.keyField === props.keyField)?.color }} >
                            {props.headerText}
                    </span>
                </div>
            </div>
        </div>
    )
}

const cardTemplate = (props) => {
    return (
        <div key={props.id} className="rounded-lg shadow-lg bg-slate-100 dark:bg-slate-600 px-3 py-2">
            <div className='relative pb-10 flex items-center'>
                <img onClick={props.nav}src={props.avatar || "/images/user.png"} className="w-14 h-14 border-2 border-slate-200 rounded-full mr-2 cursor-pointer object-cover" alt="avatar" />
                <div className="flex flex-col px-2">
                    <Tooltip title={props?.name || ''} onClick={props.nav}>
                        <span className="mt-2 block text-sm font-bold whitespace-nowrap text-slate-700 dark:text-slate-100 hover:text-cyan-800 cursor-pointer">
                            {props?.name?.length > 12 ? `${props.name.substr(0, 12)} ..` : props.name}
                        </span>
                    </Tooltip>
                    <div className='flex items-center mt-2'>
                        <Tooltip title={'Send Custom Email'} onClick={props.sendMail} >
                            <span className="w-fit p-1 rounded-full border border-slate-100 transition-all duration-800 hover:bg-slate-400 text-slate-500 dark:text-slate-100 hover:text-white cursor-pointer">
                                <BiMailSend size={20} />
                            </span>
                        </Tooltip>
                        <Tooltip title={'Create New Order'} onClick={props.createOrder}>
                            <span className="mx-2 w-fit p-1 rounded-full border border-slate-100 transition-all duration-800 hover:bg-slate-400 text-slate-500 dark:text-slate-100 hover:text-white cursor-pointer">
                                <MdAddShoppingCart size={20} />
                            </span>
                        </Tooltip>
                    </div>
                </div>
                <div className='w-full flex items-center justify-between absolute bottom-0'>
                    <div className='flex items-center'>
                        &nbsp;
                        {/* <GiTakeMyMoney className="text-lg mr-1 text-slate-500 dark:text-slate-100" />
                        <span className='text-sm font-bold'>{`${props?.paid || 0} ${settingsData.organization_currency}`}</span> */}
                    </div>
                    <Rating size='small' value={props?.rate} readOnly />
                </div>
            </div>
        </div>
    )
}

function CustomerStatus() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { customersList } = useSelector(state => state.customers);
    const { user } = useSelector(state => state.auth);
    const [data, setData] = useState([]);
    const [selectedCustomerEmail, setSelectedCustomerEmail] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const customerRating = (paid) => {
        let result = 0;
        if (paid) {
          getCustomerRatingRange.forEach(item => {
            if (paid >= item.min && paid < item.max) {
              result = item.value;
            }
          }
          );
          return result;
        } else {
          return 0;
        }
      }
 
    useEffect(() => {
        if (user?.role < 4) {
        dispatch(getCustomersListRequest());
        }
    }, [dispatch, user.role]);

    useEffect(() => {
        setData([])
        if (customersList?.length) {
            customersList.map(customer => 
                setData((prevData) => [...prevData, {
                    id: customer._id,
                    name: customer.name,
                    avatar: customer.avatar,
                    email: customer.email,
                    rate: customerRating(customer.paid),
                    status: getCustomerStatus.find(status => status.id === customer.status).name,
                    color: getCustomerStatus.find(status => status.id === customer.status).color,
                    paid: customer.paid?.toFixed(3),
                    nav: () => navigate(`/crm/customers/view/${customer._id}`),
                    sendMail: () => {setSelectedCustomerEmail(customer.email); setShowModal(true)},
                    createOrder: () => navigate(`/crm/orders/add/?customer=${customer._id}`)
                }])
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customersList]);
        
   // console.log(data);


    if (user?.role > 4) {
        return <NotAuthorized />
    }

    return (
        <div className='m-2 md:m-6 p-2 md:p-6'>
            <div className='mt-20 sm:mt-4'>
                <KanbanComponent
                    id="kanban"
                    keyField="status"
                    dataSource={data}
                    cardTemplate={cardTemplate}
                    cardSettings={{ template: cardTemplate }}
                    allowDragAndDrop={false}
                    all
                    >
                    <ColumnsDirective>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        {kanbanGrid.map((item, index) => settingsData.company_type === "Products" && index === 2 ? null : <ColumnDirective key={index} template={columnTemplate} {...item} />)}
                    </ColumnsDirective>
                </KanbanComponent>
            </div>
            <SendCustomMail showModal={showModal} closeModal={() => setShowModal(false)} email={selectedCustomerEmail} />
        </div>
    )
}

export default CustomerStatus