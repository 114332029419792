import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSchedule } from 'react-icons/ai';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { getSupplierRequest } from '../../store/suppliers/actions';
import { deletePurshaceRequest, getPurshacesRequest } from '../../store/purshaces/actions';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import { settingsData } from '../../data/dummy';
import { useTranslation } from 'react-i18next';
import { MdArrowBack, MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { BiMoney } from 'react-icons/bi';

const Purshaces = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { purshaces, pageCount, currentPage, totalCount } = useSelector(state => state.purshaces);
  const { supplier } = useSelector(state => state.suppliers);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedPurshaceId, setSelectedPurshaceId] = useState(null);
  const { currentColor } = useStateContext();
  const { user } = useSelector(state => state.auth);
  const [payload, setPayload] = useState({
    id: id,
    search: '',
    page: 0,
  });

  useEffect(() => {
    if (id) {
      setPayload({
        id: id,
      });
      dispatch(getSupplierRequest(id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (user?.role < 3 ) {
      dispatch(getPurshacesRequest(payload));
    }
  }, [dispatch, payload, user?.role]);

     
  const handleDeletePurshace = () => {
    dispatch(deletePurshaceRequest(selectedPurshaceId));
    setConfirmModal(false);
  }

  const onPageChange = (page) => {
    if (page.selected !== (currentPage + 1)) {
      setPayload({ ...payload, page: page.selected });
    }
  }

  const countProducts = () => {
    let products = [];
    purshaces?.forEach(purshace => {
      if (!products.includes(purshace.product._id)) {
        products.push(purshace.product._id);
      }
    }
    );
    return products.length;
  }

  const calculateTotal = () => {
    let total = 0;
    purshaces?.forEach(purshace => {
      total += purshace.total;
    }
    );
    return total;
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }

  return (
    <div className="m-2 md:m-6 p-2 md:p-6">
      <div className='mt-20 sm:mt-0 transition-all flex items-center duration-800 text-slate-700 dark:text-slate-200 hover:text-cyan-500 cursor-pointer mb-4 text-xl px-2 sm:px-4'
          onClick={() => navigate("/crm/suppliers")}>
            <MdArrowBack className='mr-2' /> Suppliers List
      </div>
      <div className="flex justify-between items-center mb-4">
        <p className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	mb-0 px-4" 
            style={{ color: currentColor }}>
             {supplier?.name}
        </p>
      </div>
      <div className="flex justify-between flex-wrap sm:flex-nowrap items-center">
        <div className='w-full sm:w-1/3 p-4'>
          <div className='relative h-28 border border-slate-100 bg-slate-100 p-4'>
            <span className='flex justify-center items-center w-12 h-12 rounded-md absolute -top-2 -right-2 bg-slate-200 shadow-sm'>
                <AiOutlineSchedule className='text-2xl' style={{ color: currentColor }} />
            </span>
            <span className='block w-full text-4xl text-slate-600 font-bold mb-3'> {totalCount}</span>
            <span className='block w-full text-right text-lg' style={{ color: currentColor }}>{t("purchases_value")}</span>
          </div>
        </div>
        <div className='w-full sm:w-1/3 p-4'>
          <div className='relative h-28 border border-slate-100 bg-slate-100 p-4'>
            <span className='flex justify-center items-center w-12 h-12 rounded-md absolute -top-2 -right-2 bg-slate-200 shadow-sm'>
                <MdOutlineProductionQuantityLimits className='text-2xl' style={{ color: currentColor }} />
            </span>
            <span className='block w-full text-4xl text-slate-600 font-bold mb-3'> {countProducts()}</span>
            <span className='block w-full text-right text-lg' style={{ color: currentColor }}>{t("products_value")}</span>
          </div>
        </div>
        <div className='w-full sm:w-1/3 p-4'>
          <div className='relative h-28 border border-slate-100 bg-slate-100 p-4'>
            <span className='flex justify-center items-center w-12 h-12 rounded-md absolute -top-2 -right-2 bg-slate-200 shadow-sm'>
                <BiMoney className='text-2xl' style={{ color: currentColor }} />
            </span>
            <span className='block w-full text-4xl text-slate-600 font-bold mb-3'> {calculateTotal()}&nbsp;<span className='text-sm'>{settingsData.organization_currency}</span></span>
            <span className='block w-full text-right text-lg' style={{ color: currentColor }}>{t("ca")}</span>
          </div>
        </div>
      </div>
      <div className='flex justify-end items-center py-4'>
        <input type="text" className="w-1/3 sm:w-1/4 px-4 py-3 bg-gray-100 focus:outline-none" placeholder="Search by name" value={payload.search} onChange={(e) => setPayload({ ...payload, search: e.target.value })} />
      </div>
      <div className='table_container'>
        <table className='w-full rounded-md table-auto'>
          <thead className='border border-gray-100 bg-gray-100'>
            <tr>
              <th className='px-4 py-3 text-sm text-left'>Date</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-left'>Product</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-right'>Price</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-center'>Quantity</th>
              <th className='px-4 py-3 text-sm text-right'>Total</th>
              <th className='px-4 py-3 text-sm text-center'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              purshaces.map(purshace => (
                <tr key={purshace._id}>
                 
                  <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-left'>{moment(purshace.date).format("DD/MM/YYYY")}</td>
                  <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-left'>{purshace.product?.name}</td>
                  <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-right'>
                    <b>{purshace.price}</b>&nbsp;
                    <span className="text-xs">{settingsData.organization_currency}</span>
                  </td>
                  <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-center'>
                    <b>{purshace.quantity}</b>&nbsp;
                    <span className="text-xs">{purshace.product?.unit}</span>
                  </td>
                  <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-right'>
                    <b>{purshace.total}</b>&nbsp;
                    <span className="text-xs">{settingsData.organization_currency}</span>
                  </td>
                  <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 flex justify-center items-center h-full'>
                    <AiOutlineEdit className="mx-1 my-3 text-2xl text-green-500 hover:text-green-800 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/crm/purshaces/edit/${purshace?._id}`);
                                    }} />
                    <AiOutlineDelete className="mx-1 my-3 text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedPurshaceId(purshace?._id);
                                      setConfirmModal(true);
                                    }} />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Pagination
         pageCount={pageCount}
         onPageChange={(page) => onPageChange(page)}
        />
      </div>

      <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${confirmModal ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className='text-lg text-bold mb-6'>Confirm Delete?</p>
          <div className="flex justify-end">
            <button className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md" onClick={() => setConfirmModal(false)}>
              Cancel
            </button>
            <button className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md" onClick={() => handleDeletePurshace()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Purshaces;
