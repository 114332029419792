import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiUserAddLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFileProtect } from 'react-icons/ai';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { deleteSupplierRequest, getSuppliersRequest } from '../../store/suppliers/actions';

const Suppliers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { suppliers } = useSelector(state => state.suppliers);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const { currentColor } = useStateContext();
  const { user } = useSelector(state => state.auth);
  const [search, setSearch] = useState('');
  const [filtredData, setFiltredData] = useState([]);
 
  useEffect(() => {
    if (user?.role < 3) {
      dispatch(getSuppliersRequest());
    }
  }, [dispatch, user.role]);

  useEffect(() => {
    if (suppliers.length > 0) {
      if (search.length > 0) {
        setFiltredData(suppliers.filter(supplier => supplier.name.toLowerCase().includes(search.toLowerCase())));
      } else {
        setFiltredData(suppliers);
      }
    }
  } , [search, suppliers]);

     
  const handleDeleteSupplier = () => {
    dispatch(deleteSupplierRequest(selectedSupplierId));
    setConfirmModal(false);
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }

  return (
    <div className="m-2 md:m-6 p-2 md:p-6">
      <div className="flex justify-between items-center mt-20 sm:mt-0">
        <p className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	" 
            style={{ color: currentColor }}>
             {filtredData?.length} Suppliers
        </p>
        <RiUserAddLine className="text-3xl	cursor-cell"
                          style={{ color: currentColor }}
                          onClick={() => navigate("/crm/suppliers/add")} />
      </div>
      <div className='flex justify-end items-center py-4'>
        <input type="text" className="w-1/3 sm:w-1/4 px-4 py-3 bg-gray-100 focus:outline-none" placeholder="Search by name" value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className='table_container'>
        <table className='w-full rounded-md table-auto'>
          <thead className='border border-gray-100 bg-gray-100'>
            <tr>
              <th className='px-4 py-3 text-sm text-left'>Name</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-left'>Email</th>
              <th className='px-4 py-3 text-sm text-left'>Phone</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-left'>Address</th>
              <th className='px-4 py-3 text-sm text-center'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              filtredData.map(supplier => (
                <tr key={supplier._id}>
                 
                  <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-left'>{supplier.name}</td>
                  <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-left'>{supplier.email}</td>
                  <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-left'>{supplier.phone}</td>
                  <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 dark:text-white text-left'>{supplier.address}</td>
                  <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 flex justify-center items-center h-full'>
                    <AiOutlineFileProtect className="mx-1 my-3 text-2xl text-pink-500 hover:text-pink-800 cursor-pointer" 
                                          onClick={() => navigate(`/crm/purshaces/${supplier._id}`)} />
                    <AiOutlineEdit className="mx-1 my-3 text-2xl text-green-500 hover:text-green-800 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate(`/crm/suppliers/edit/${supplier?._id}`);
                                    }} />
                    <AiOutlineDelete className="mx-1 my-3 text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedSupplierId(supplier?._id);
                                      setConfirmModal(true);
                                    }} />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${confirmModal ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className='text-lg text-bold mb-6'>Confirm Delete?</p>
          <div className="flex justify-end">
            <button className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md" onClick={() => setConfirmModal(false)}>
              Cancel
            </button>
            <button className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md" onClick={() => handleDeleteSupplier()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Suppliers;
