import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { useDispatch } from 'react-redux';
import { singlePurchaseRequest } from '../../store/products/actions';
import { settingsData } from '../../data/dummy';

function SinglePurshace({show, close, product}) {
    const dispatch = useDispatch();
    const { currentColor } = useStateContext();
    const [feedback, setFeedback] = useState('');
    const [formData, setFormData] = useState({
        id: null,
        price: 0,
        quantity: 0,
        total: 0,
    });

    const clearForm = () => {
        setFormData({
            id: null,
            price: 0,
            quantity: 0,
            total: 0,
        });
        setFeedback('');
    }

    useEffect(() => {
        if (product?._id && show) {
            setFormData({
                id: product._id,
                price: product.buyPrice,
                quantity: 0,
                total: 0,
            });
        }
    }, [product, show]);              

    useEffect(() => {
        if (formData.quantity > 0) {
            setFormData({
                ...formData,
                total: formData.quantity * formData?.price,
            });
        } else {
            setFormData({
                ...formData,
                total: 0,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [formData.quantity, formData.price]);

    const validation = () => {
        let isValid = true;
        if (formData.quantity === 0) {
            setFeedback('Please enter a valid quantity value');
            isValid = false;
        }
        if (formData.id === 0) {
            setFeedback('Please select a product');
            isValid = false;
        }
        return isValid;
    }

    const handleSubmit = () => {
        setFeedback('');
        if (validation()) {
            dispatch(singlePurchaseRequest(formData));
            setTimeout(() => {
                clearForm();
                close();
            }, 1000);
        }
    }

    console.log(product, formData)

    return (
        <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-40 ${show ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
            <div className="w-11/12 sm:w-2/5 py-6 px-10 shadow-xl rounded-md max-h-590 overflow-y-auto bg-slate-100 dark:bg-slate-500">
                <h3 className='text-2xl font-bold my-4 text-center' style={{ color: currentColor }}>Single Purshace</h3>
                <div className='confimrModalContent pt-4'>
                    <p className='px-2 text-md mb-2'>Product Name: <b>{product?.name}</b></p>
                    <p className='px-2 text-md mb-2'>Product Category: <b>{product?.category}</b></p>
                    <div className='flex w-full mt-4 mb-4'>
                        <div className='relative w-1/2 px-2'>
                            <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Buying Price<span className='text-red-600 ml-2'>*</span></label>
                            <input name="quantity" type='number' className='w-full py-3 px-4 bg-white focus:outline-none'
                                value={formData.price}
                                onChange={(e) => {
                                    setFormData({ ...formData, price: parseInt(e.target.value) });
                                    setFeedback('');
                                }}
                            />
                            <strong className='absolute bottom-3 right-12'>{settingsData.organization_currency}</strong>
                        </div>
                        <div className='relative w-1/2 px-2'>
                        </div>
                    </div>
                    <p className='px-2 text-md mb-2'>In Stock: <b>{product?.stock}</b></p>
                    <div className='flex w-full mt-4 mb-4 sm:mb-0'>
                        <div className='relative w-1/2 px-2'>
                            <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Quantity To Add<span className='text-red-600 ml-2'>*</span></label>
                            <input name="quantity" type='number' className='w-full py-3 px-4 bg-white focus:outline-none'
                                value={formData.quantity}
                                onChange={(e) => {
                                    setFormData({ ...formData, quantity: parseInt(e.target.value) });
                                    setFeedback('');
                                }}
                            />
                            <strong className='absolute bottom-3 right-12'>{product?.unit}</strong>
                        </div>
                        <div className='relative w-1/2 px-2'>
                            <label className='block text-sm dark:text-gray-200 text-gray-600 mb-2'>Total Purshace<span className='text-red-600 ml-2'>*</span></label>
                            <input name="total" type='number' className='w-full py-3 px-4 bg-white focus:outline-none'
                                value={formData.total}
                                readOnly
                                onChange={(e) => {
                                    setFormData({ ...formData, total: e.target.value });
                                    setFeedback('');
                                }}
                            />
                            <strong className='absolute bottom-3 right-12'>{settingsData.organization_currency}</strong>
                        </div>
                    </div>
                   
                    <div className='mt-10 mb-4 flex justify-center items-center'>
                        <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md text-white font-bold focus:outline-none'
                                style={{ backgroundColor: currentColor }}
                                type='button'
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}>
                            Confirm
                        </button>
                        <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md bg-white border font-bold focus:outline-none'
                                style={{ borderColor: currentColor, color: currentColor }}
                                type='button'
                                onClick={(e) => {
                                    close();
                                }}>
                            Cancel
                        </button>
                    </div>
                    <p className='text-center w-full text-red-400 mt-4'>{feedback}</p>
                </div>
            </div>
        </div>
    )
}

export default SinglePurshace