//Purshaces
//Get Purshaces
export const GET_PURSHACES_REQUEST = "GET_PURSHACES_REQUEST";
export const GET_PURSHACES_RECEIVE = "GET_PURSHACES_RECEIVE";
//Get Product Purshaces
export const GET_PRODUCT_PURSHACES_REQUEST = "GET_PRODUCT_PURSHACES_REQUEST";   
export const GET_PRODUCT_PURSHACES_RECEIVE = "GET_PRODUCT_PURSHACES_RECEIVE";
//Get Purshace
export const GET_PURSHACE_REQUEST = "GET_PURSHACE_REQUEST";
export const GET_PURSHACE_RECEIVE = "GET_PURSHACE_RECEIVE";
//Create Purshace
export const CREATE_PURSHACE_REQUEST = "CREATE_PURSHACE_REQUEST";
export const CREATE_PURSHACE_RECEIVE = "CREATE_PURSHACE_RECEIVE";
//Update Purshace
export const UPDATE_PURSHACE_REQUEST = "UPDATE_PURSHACE_REQUEST";
export const UPDATE_PURSHACE_RECEIVE = "UPDATE_PURSHACE_RECEIVE";
//Delete Purshace
export const DELETE_PURSHACE_REQUEST = "DELETE_PURSHACE_REQUEST";
export const DELETE_PURSHACE_RECEIVE = "DELETE_PURSHACE_RECEIVE";