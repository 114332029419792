import * as TYPES from "./types";
//Suppliers
// Get Suppliers
export const getSuppliersRequest = (payload) => ({
  type: TYPES.GET_SUPPLIERS_REQUEST,
  payload,
});
export const getSuppliersReceive = (payload) => ({
  type: TYPES.GET_SUPPLIERS_RECEIVE,
  payload,
});
//Get Supplier
export const getSupplierRequest = (payload) => ({
  type: TYPES.GET_SUPPLIER_REQUEST,
  payload,
});
export const getSupplierReceive = (payload) => ({
  type: TYPES.GET_SUPPLIER_RECEIVE,
  payload,
});
//Create Supplier
export const createSupplierRequest = (payload, navigate) => ({
  type: TYPES.CREATE_SUPPLIER_REQUEST,
  payload,
  navigate,
});
export const createSupplierReceive = (payload) => ({
  type: TYPES.CREATE_SUPPLIER_RECEIVE,
  payload,
});
//Update Supplier
export const updateSupplierRequest = (payload, navigate) => ({
  type: TYPES.UPDATE_SUPPLIER_REQUEST,
  payload,
  navigate,
});
export const updateSupplierReceive = (payload) => ({
  type: TYPES.UPDATE_SUPPLIER_RECEIVE,
  payload,
});
//Delete Supplier
export const deleteSupplierRequest = (id) => ({
  type: TYPES.DELETE_SUPPLIER_REQUEST,
  id,
});
export const deleteSupplierReceive = (id) => ({
  type: TYPES.DELETE_SUPPLIER_RECEIVE,
  id,
});