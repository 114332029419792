import React, { useEffect } from 'react'
import { useStateContext } from '../../contexts/ContextProvider';
import { settingsData } from '../../data/dummy';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getServicesRequest } from '../../store/services/actions';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { BsGlobe2 } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { getProductsRequest } from '../../store/products/actions';
import { getOrderRequest } from '../../store/orders/actions';

function PrintOrder({show, close, orderID, type}) {
  const dispatch = useDispatch();
  const { order } = useSelector(state => state.orders);
  const { services } = useSelector(state => state.services);
  const { products } = useSelector(state => state.products);
  const { currentColor } = useStateContext();
  const printRef = React.useRef();

  useEffect(() => {
    if (orderID) {
      dispatch(getOrderRequest(orderID))
      if (settingsData.company_type === "Services") {
        dispatch(getServicesRequest());
      } else if (settingsData.company_type === "Products") {
        dispatch(getProductsRequest());
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [dispatch, orderID])

  const handleDownload = async (e) => {
    e.preventDefault();
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${type}-${order?.ref}.pdf`);	
  };

  return (
    <div className={`transition-all duration-800 fixed top-0 left-0 bg-black bg-opacity-25 flex justify-center items-center w-screen h-screen ${show ? 'opacity-1 z-50 pointer-events-all' : 'opacity-0 z-0 pointer-events-none'}`}>
      <div className='w-full sm:w-2/3 rounded-lg shadow-lg p-4 bg-slate-100'>
        <div className='h-64 overflow-x-hidden overflow-y-auto'> 
          <div ref={printRef} className='h-full flex flex-col items-between relative bg-white rounded-md py-10 pl-20 pr-32' style={{ height: 3508 }}>
              <div>
                <div className='flex justify-between items-center mb-6 mt-6'>
                  <div className='flex items-center'>
                    <img className='h-24 w-auto object-contain' src={settingsData.organization_logo} alt="Logo"/>
                    <h1 className='text-4xl ml-4 -mt-3'>{settingsData.organization_name}</h1>
                  </div>
                  <div>
                    {order?.qrCode && <img src={order?.qrCode} alt="QR Code" className='h-24 w-auto object-contain'/>}
                  </div>
                </div>
                <div className='flex justify-end mb-10'>
                  <h2 className='text-2xl font-bold'>{`${type}: `}{type === 'Invoice' ? settingsData.order_reference_prefix : settingsData.quost_reference_prefix}{order?.ref}</h2>
                </div>
                <div className='flex justify-between items-end mb-6 mt-20'>
                  <div>
                    <p className='text-xl mb-2'>Client: <strong>{order?.customer?.name}</strong></p>
                    <p className='text-xl mb-2'>Phone: <strong>{order?.customer?.phone}</strong></p>
                    <p className='text-xl mb-2'>Email: <strong>{order?.customer?.email}</strong></p>
                    <p className='text-xl mb-2'>Address: <strong>{order?.customer?.address}</strong></p>
                  </div>
                  <div>
                    <p className='text-xl mb-2'><strong>{settingsData.organization_city}, {moment().format('DD/MM/YYYY')}</strong></p>
                  </div>
                </div>
                <div>
                  <table className='w-full rounded-md table-auto'>
                    <thead className='border border-gray-100 bg-gray-100'>
                      <tr>
                        <th className='px-2 sm:px-4 py-3 text-lg text-left'>Description</th>
                        <th className='px-2 sm:px-4 py-3 text-lg text-right'>Unit Price</th>
                        <th className='hidden sm:table-cell px-2 sm:px-4 py-3 text-lg text-center'>Quantity</th>
                        <th className='px-2 sm:px-4 py-3 text-lg text-left'>Unit</th>
                        <th className='px-2 sm:px-4 py-3 text-lg text-right'>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.items?.map(item => (
                        <tr key={item._id} >
                          <td className='text-lg border border-gray-100 px-2 sm:px-4 py-2 dark:text-slate-100 text-left'>
                            { settingsData.company_type === "Services" 
                                ? <span>{services?.find(service => service._id === item.serviceId)?.name} - {services?.find(service => service._id === item.serviceId)?.description}</span>
                                : <span>{products?.find(product => product._id === item.productId)?.name} - {products?.find(product => product._id === item.productId)?.description}</span>
                            }  
                          </td>
                          <td className='hidden sm:table-cell whitespace-nowrap	border border-gray-100 px-2 sm:px-4 py-2 text-right text-lg font-bold'>
                            <b>{item.price.toFixed(3)}</b> {settingsData.organization_currency}
                          </td>
                          <td className='hidden sm:table-cell border border-gray-100 px-2 sm:px-4 py-2 text-center text-lg font-bold'>
                            {item.quantity}
                          </td>
                          <td className='hidden sm:table-cell border border-gray-100 px-2 sm:px-4 py-2 text-lg'>
                            { settingsData.company_type === "Services" 
                                ? <span>{services?.find(service => service._id === item.serviceId)?.unit}</span>
                                : <span>{products?.find(product => product._id === item.productId)?.unit}</span>
                            }
                          </td>
                          <td className='whitespace-nowrap border border-gray-100 px-2 sm:px-4 py-2 text-right text-lg font-bold'><b>
                            {item.total?.toFixed(3)}</b> {settingsData.organization_currency}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='mt-4 w-full'>
                        <p className='w-full text-right text-lg'><span>Subtotal: </span><b>{order?.totalHT?.toFixed(3)}</b> {settingsData.organization_currency}</p>
                        <p className='w-full text-right text-lg'><span>Total Tax: </span><b>{order?.totalTax?.toFixed(3)}</b> {settingsData.organization_currency}</p>
                        <p className='w-full text-right text-lg'><span>Total: </span><b>{order?.totalTTC?.toFixed(3)}</b> {settingsData.organization_currency}</p>
                  </div>
              </div>
              {/* <div className="my-10 flex justify-end items-center px-8">
                    <img src='/images/signature.png' alt="Signature" className='w-60 h-auto' />
              </div> */}
              <div className='w-full px-10 py-6' style={{ marginTop: "900px" }}>
                  <div className='w-full rounded-full py-2 px-6 flex justify-between items-center mb-2'>
                    <p className='flex items-center text-black text-xs'><GoLocation className='mr-2' /><span className='-mt-3'>{settingsData.organization_address}</span></p>
                    <p className='flex items-center text-black text-xs'><span className='-mt-3'>RIB: {settingsData.organization_rib}</span></p>
                    <p className='flex items-center text-black text-xs'><span className='-mt-3'>MF: {settingsData.fiscal_number}</span></p>
                  </div>
                  <div className='w-full rounded-full py-2 px-6 flex justify-between items-center bg-gradient-to-l from-rose-800 to-blue-800'>
                    <p className='flex items-center text-white text-xs'><AiOutlinePhone className='mr-2' /><span className='-mt-3'>{settingsData.organization_phone}</span></p>
                    <p className='flex items-center text-white text-xs'><BsGlobe2 className='mr-2' /><span className='-mt-3'>{settingsData.organization_website}</span></p>
                    <p className='flex items-center text-white text-xs'><AiOutlineMail className='mr-2' /><span className='-mt-3'>{settingsData.organization_email}</span></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
          
        <div className='mt-10 mb-4 flex justify-center items-center'>
          <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md text-white font-bold focus:outline-none'
                  style={{ backgroundColor: currentColor }}
                  type='submit'
                  onClick={(e) => handleDownload(e)}>
            Download
          </button>
          <button className='w-auto sm:w-48 mx-3 py-3 px-4 sm:px-6 text-sm rounded-md bg-white borderfont-bold focus:outline-none'
                  style={{ borderColor: currentColor, color: currentColor }}
                  onClick={(e) => {
                    e.preventDefault();
                    close();
                  }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default PrintOrder