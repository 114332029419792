//Projects
//Get Projects
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_RECEIVE = "GET_PROJECTS_RECEIVE";
//Get Project
export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_RECEIVE = "GET_PROJECT_RECEIVE";
//Suspend Project
export const SUSPEND_PROJECT_REQUEST = "SUSPEND_PROJECT_REQUEST";
export const SUSPEND_PROJECT_RECEIVE = "SUSPEND_PROJECT_RECEIVE";
//Active Project
export const ACTIVE_PROJECT_REQUEST = "ACTIVE_PROJECT_REQUEST";
export const ACTIVE_PROJECT_RECEIVE = "ACTIVE_PROJECT_RECEIVE";
//Close Project
export const CLOSE_PROJECT_REQUEST = "CLOSE_PROJECT_REQUEST";
export const CLOSE_PROJECT_RECEIVE = "CLOSE_PROJECT_RECEIVE";
 //Get My Projects
export const GET_MY_PROJECTS_REQUEST = "GET_MY_PROJECTS_REQUEST";
export const GET_MY_PROJECTS_RECEIVE = "GET_MY_PROJECTS_RECEIVE";