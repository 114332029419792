import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { getCustomerRequest } from '../../store/customers/actions';
import { getCustomerRatingRange, getCustomerStatus, getOrderStatus, settingsData } from '../../data/dummy';
import Rating from '@mui/material/Rating';
import Satisfaction from '../../components/Satisfaction';
import { AiOutlineMail, AiOutlinePhone, AiOutlineLink, AiOutlineFileProtect, AiOutlineProject, AiOutlineEdit } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go';
import { MdOutlinePayments, MdArrowBack, MdOutlineMoneyOffCsred } from 'react-icons/md';
import { Tooltip } from '@mui/material';
import PrintOrder from '../Orders/PrintOrder';
import OrderAction from '../../components/OrderAction';
import moment from 'moment';
import { VscFilePdf, VscGithubAction } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';

const CustomerView = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { customer } = useSelector(state => state.customers);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [printType, setPrintType] = useState(null);
  const { currentColor } = useStateContext();
 
  useEffect(() => {
    if (id) {
      dispatch(getCustomerRequest(id));
    }
  }, [dispatch, id]);

  const customerRating = (paid) => {
    let result = 0;
    if (paid) {
      getCustomerRatingRange.forEach(item => {
        if (paid >= item.min && paid < item.max) {
          result = item.value;
        }
      }
      );
      return result;
    } else {
      return 0;
    }
  }

  const unpaidCalculate = (orders) => {
    let result = 0;
    orders?.filter(ord => [2,4,5].includes(ord.status))?.forEach(order => {
      if (parseFloat(order.total) - parseFloat(order.paid) > 0) {
        result += parseFloat(order.total) - parseFloat(order.paid);
      }
    }
    );
    return result;
  }

  
  if (user?.role > 4) {
    return <NotAuthorized />
  }
    
  return (
    <div className="m-2 md:m-6 p-2 md:p-4 dark:bg-main-dark-bg bg-transparent">
      <div className="relative w-full mx-auto py-6 px-2 sm:px-4 mt-20 sm:mt-0">
          <div className='transition-all flex items-center duration-800 text-slate-700 dark:text-slate-200 hover:text-cyan-500 cursor-pointer mb-4 text-xl px-2 sm:px-4'
              onClick={() => navigate("/crm/customers")}>
                <MdArrowBack className={`${i18n.language === 'ar' ? 'ml-2 rotate-180' : 'mr-2'}`} /> {t("customersList")}
          </div>
          <div className='flex flex-col sm:flex-row w-full'>
            <div className='relative w-full sm:w-2/3 p-4 mx-auto sm:mx-4 rounded-md shadow-md bg-slate-100 dark:bg-slate-600 mb-4 sm:mb-0' style={{ borderColor: currentColor }}>
                <div className='flex flex-col sm:flex-row justify-center sm:justify-start items-center sm:items-start'>
                    <div className={`flex flex-col justify-center items-center ${i18n.language === "ar" ? 'ml-3 sm:ml-4' : 'mr-3 sm:mr-4'} mb-2 sm:mb-0`}>
                        <img className='block mb-3 w-24 sm:w-48 h-24 sm:h-48 rounded-xl shadow-xl border-2 border-slate-200 object-cover' src={customer?.avatar || "/images/avatar.png"} alt={customer?.name} />
                        <Satisfaction value={customer?.satisfaction || 1} readOnly={true} size="small" />
                    </div>
                    <div className='relative flex flex-col justify-center items-start'>
                        <h3 className='text-md sm:text-2xl font-bold text-slate-600 dark:text-slate-50 '>{customer?.name}</h3>
                        <Rating size='small' value={customerRating(customer?.paid)} readOnly />
                        {customer?.phone && <p className='flex items-center text-xs sm:text-sm font-bold text-slate-600 dark:text-slate-300 mt-3 mb-2'>
                          <AiOutlinePhone className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                          <span style={{direction: "ltr", textAlign: i18n.language === "ar" ? "right" : "left"}}>{customer?.phone}</span>
                        </p>}
                        {customer?.email && <p className='flex items-center text-xs sm:text-sm font-bold text-slate-600 dark:text-slate-300  mb-2'>
                          <AiOutlineMail className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                          {customer?.email}
                        </p>}
                        {customer?.website && <p className='flex items-center text-xs sm:text-sm font-bold text-slate-600 mb-2 transition-all duration-800 hover:text-cyan-400 dark:text-slate-300 dark:hover:text-cyan-400'><a href={customer?.website} target="_blank" rel="noreferrer" className='flex items-center'>
                          <AiOutlineLink className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                          {customer?.website}</a>
                        </p>}
                        {customer?.address && <p className='flex items-center text-xs sm:text-sm font-bold text-slate-600 dark:text-slate-300  mb-2'>
                          <GoLocation className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                          {customer?.address}
                        </p>}
                        {customer?.city && <p className='flex items-center text-xs sm:text-sm font-bold text-slate-600 dark:text-slate-300  mb-2'>
                          <GoLocation className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                          {customer?.city}
                        </p>}
                    </div>
                </div>
                <span className={`absolute ${i18n.language === 'ar' ? 'left-2' : 'right-2'} top-2 sm:top-auto bottom-auto sm:bottom-2 mt-4 rounded-full py-1 px-6 w-fit shadow-sm text-white`} style={{ background: getCustomerStatus?.find(status => status.id === customer?.status)?.color }}>{t(getCustomerStatus?.find(status => status.id === customer?.status)?.name)}</span>
            </div>
            <div className='w-full sm:w-1/3 p-2 sm:p-4 mx-auto sm:mx-4' style={{ borderColor: currentColor }}>
              <p className='flex items-center text-md font-bold text-slate-600 dark:text-slate-300 mt-3 mb-3'>
                <AiOutlineFileProtect className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                {t("total_orders")}:&nbsp;<b>{customer?.orders?.length || 0}</b>
              </p>
              {settingsData.company_type === "Services" && <p className='flex items-center text-md font-bold text-slate-600 dark:text-slate-300 mt-3 mb-3'>
                <AiOutlineProject className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                {t("total_projects")}:&nbsp;<b>{customer?.projects?.length || 0}</b>
              </p>}
              <p className='flex items-center text-md font-bold text-slate-600 dark:text-slate-300 mt-3 mb-3'>
                <MdOutlinePayments className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                {t('total_paid')}:&nbsp;<b>{customer?.paid?.toFixed(2)} {t(settingsData.organization_currency)}</b></p>
              <p className='flex items-center text-md font-bold text-slate-600 dark:text-slate-300 mt-3 mb-3'>
                <MdOutlineMoneyOffCsred className={`${i18n.language === "ar" ? 'ml-2' : 'mr-2'} text-xl text-gray-600 dark:text-slate-100`} />
                {t('total_unpaid')}:&nbsp;<b className='text-red-400'>{customer && unpaidCalculate(customer?.orders).toFixed(2)} {t(settingsData.organization_currency)}</b></p>
            </div>
          </div>
          <div className='mt-3 flex w-full'>
            <div className='w-full p-4 mx-auto sm:mx-4' style={{ borderColor: currentColor }}>
                <h4 className='mb-3 font-bold text-slate-900 dark:text-slate-100'>{t("notes")}</h4>
                <p className="text-gray-800 dark:text-white">{customer?.notes}</p>
            </div>
          </div>
          <div className='mt-3 flex w-full'>
            <div className='w-full p-2 sm:p-4 mx-auto sm:mx-4 rounded-md' style={{ borderColor: currentColor }}>
                <h4 className='mb-3 font-bold text-slate-900 dark:text-slate-100'>{t("ordersList")}</h4>
                <div>
                  <table className='w-full rounded-md table-auto'>
                    <thead className='border border-gray-100 bg-gray-100'>
                      <tr>
                        <th className={`hidden sm:table-cell px-4 py-3 text-sm dark:text-slate-500 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>{t('ref')}</th>
                        <th className={`hidden sm:table-cell px-4 py-3 text-sm dark:text-slate-500 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>{t('date')}</th>
                        <th className='px-4 py-3 text-sm dark:text-slate-500 text-right'>{t('total')}</th>
                        <th className='px-4 py-3 text-sm dark:text-slate-500 text-right'>{t('unpaid')}</th>
                        <th className='hidden sm:table-cell px-4 py-3 text-sm dark:text-slate-500 text-center'>{t('status')}</th>
                        <th className='px-4 py-3 text-sm dark:text-slate-500 text-center'>{t('actions')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer?.orders?.map(order => (
                        <tr key={order._id} className="cursor-pointer transition-all duration-800 hover:bg-slate-400 dark:hover:bg-slate-500" onClick={() => navigate(`/crm/orders/view/${order._id}`)} >
                          <td className='hidden sm:table-cell text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-1 dark:text-slate-100'>{settingsData.order_reference_prefix}{order.ref}</td>
                          <td className='hidden sm:table-cell text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-1 dark:text-slate-100'>{moment(order.date).format('DD/MM/YYYY')}</td>
                          <td className='border text-sm border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-1 dark:text-slate-100 text-right font-bold'>{order.total.toFixed(2)}&nbsp;{t(settingsData.organization_currency)}</td>
                          <td className='border text-sm border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-1 text-red-400 dark:text-red-200 text-right font-bold'>{[2,4,5].includes(order.status) ? (parseFloat(order.total) - parseFloat(order.paid)).toFixed(2) : "-"}&nbsp;{[2,4,5].includes(order.status) ? t(settingsData.organization_currency) : "-"}</td>
                          <td className='hidden sm:table-cell text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-1 dark:text-slate-100'>
                            <span className={`block w-fit py-1 px-3 shadow-lg rounded-full text-white text-center mx-auto text-xs`} 
                                  style={{ background: getOrderStatus?.find(status => status.id === order.status)?.color || "#AAA" }}>
                                    {t(getOrderStatus?.find(status => status.id === order.status)?.name)}
                            </span>
                          </td>
                          <td className='border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-1 dark:text-slate-100 flex h-full justify-center items-center'>
                            { order.status ===  1 && <Tooltip title="Generate Quost Estimation" onClick={(e) => {
                                                      e.stopPropagation();
                                                      setSelectedOrderId(order._id);
                                                      setPrintType('Quost Estimation');
                                                      setShowPrintModal(true);
                                                      //setTimeout(() => window.print(), 1000);
                                                      }} >
                                                      <span>
                                                        <VscFilePdf className="mx-1 my-5 text-2xl text-orange-500 hover:text-orange-800 cursor-pointer" />
                                                      </span>
                                                    </Tooltip> }
                            { order.status > 1 && <Tooltip title="Generate Invoice" onClick={(e) => {
                                                      e.stopPropagation();
                                                      setSelectedOrderId(order._id);
                                                      setPrintType('Invoice');
                                                      setShowPrintModal(true);
                                                      //setTimeout(() => window.print(), 1000);
                                                      }} >
                                                      <span>
                                                        <VscFilePdf className="mx-1 my-5 text-2xl text-orange-500 hover:text-orange-800 cursor-pointer" />
                                                      </span>
                                                  </Tooltip> }
                            { order.status ===  1 && <Tooltip title="Take Action" onClick={(e) =>{
                                                          e.stopPropagation();
                                                          setSelectedOrderId(order._id);
                                                          setActionModal(true);
                                                        }}>
                                                          <span>
                                                            <VscGithubAction className="mx-1 my-5 text-2xl text-violet-500 hover:text-violet-800 cursor-pointer"/>
                                                          </span>
                                                      </Tooltip> }
                          { order.status === 1 && <AiOutlineEdit className="mx-1 my-5 text-2xl text-green-500 hover:text-green-800 cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(`/crm/orders/edit/${order._id}`);
                                            }} />}
                            
                          {/*  <AiOutlineDelete className="mx-1 my-5 text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setSelectedOrderId(order._id);
                                              setConfirmModal(true);
                                            }} /> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div className='mt-6 flex w-full flex-col sm:flex-row'>
            {settingsData.company_type === "Services" && <div className={`w-full sm:w-1/2 p-2 sm:p-4 mx-auto sm:mx-4 mb-4 sm:mb-0`} style={{ borderColor: currentColor }}>
                <h4 className='mb-3 font-bold text-slate-900 dark:text-slate-100'>{t('projectsList')}</h4>
                <div>
                  <table className='w-full rounded-md table-auto'>
                    <thead className='border border-gray-100 bg-gray-100'>
                      <tr>
                        <th className='px-2 sm:px-4 py-3 text-sm dark:text-slate-500 text-left'>{t("name")}</th>
                        <th className='px-2 sm:px-4 py-3 text-sm dark:text-slate-500 text-center'>{t('start')}</th>
                        <th className='px-2 sm:px-4 py-3 text-sm dark:text-slate-500 text-center'>{t('end')}</th>
                       {/*  <th className='px-2 sm:px-4 py-3 text-sm text-center'>Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {customer?.projects?.map(project => (
                        <tr key={project._id} >
                          <td className='text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100 text-left'>{project.name}</td>
                          <td className='text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100 text-center'>{moment(project.createdAt).format('DD/MM/YYYY')}</td>
                          <td className='text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100 text-center'>{moment(project.deadline).format('DD/MM/YYYY')}</td>
                          {/* <td className='text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100'>
                            <span className={`block w-fit py-1 px-3 shadow-lg rounded-full text-white text-center mx-auto text-xs`} 
                                  style={{ background: getProjectStatus?.find(status => status.id === project.status)?.color || "#AAA" }}>
                                    {getProjectStatus?.find(status => status.id === project.status)?.name}
                            </span>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
            </div>}
            <div className={`w-full ${settingsData.company_type === "Services" && 'sm:w-1/2'} p-2 sm:p-4 mx-auto sm:mx-4`} style={{ borderColor: currentColor }}>
                <h4 className='mb-3 font-bold text-slate-900 dark:text-slate-100'>{t('paymentsList')}</h4>
                <div>
                  <table className='w-full rounded-md table-auto'>
                    <thead className='border border-gray-100 bg-gray-100'>
                      <tr>
                        <th className={`px-2 sm:px-4 py-3 text-sm dark:text-slate-500 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>{t('date')}</th>
                        {settingsData.company_type !== "Services" && <th className={`px-2 sm:px-4 py-3 text-sm dark:text-slate-500 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>{t('o_ref')}</th>}
                        <th className='px-4 py-3 text-sm text-right dark:text-slate-500'>{t('amount')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer?.payments?.map(payment => (
                        <tr key={payment._id} >
                          <td className='text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100'>{moment(payment?.date).format('DD/MM/YYYY')}</td>
                          {settingsData.company_type !== "Services" && <th className={`border border-gray-100 px-2 sm:px-4 py-3 text-sm dark:text-slate-500 ${i18n.language === "ar" ? "text-right" : "text-left"}`}>{customer?.orders?.find(ord => ord._id === payment.order)?.ref}</th>}
                          <td className='text-sm border border-gray-100 dark:border-gray-500 px-2 sm:px-4 py-2 dark:text-slate-100 text-right'><b>{parseInt(payment?.amount)?.toFixed(2)}</b> {t(settingsData.organization_currency)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
          <div className='mt-6 flex w-full'>
            <div className='w-full p-2 sm:p-4 mx-auto sm:mx-4' style={{ borderColor: currentColor }}>
                <h4 className='mb-3 font-bold text-slate-900 dark:text-slate-100'>{t("customer_activities")}</h4>
                <div className="flex items-center">
                  <div className='w-full flex flex-nowrap justify-start items-center p-4 overflow-x-auto'
                    style={{ maxWidth: "880px"}}>
                    {customer?.history?.map((history, index) => (
                      <div key={index} className='w-32 flex-none p-3 shadow-md rounded-md mr-2 bg-slate-100 dark:bg-slate-600'>
                        <div className='w-full flex justify-center items-center font-bold mb-4 text-xs text-gray-800 dark:text-slate-100'>
                          <span>{moment(history.date).format("DD/MM/YYYY HH:mm")}</span>
                        </div>
                        <div className='flex justify-center items-center'>
                          {
                            history.action === 'add' ? <img src="/images/history/add-order.png" alt="add-order" className='block mx-auto mb-4 w-10 h-10 object-cover' />
                            : history.action === 'edit' ? <img src="/images/history/edit-order.png" alt="edit-order" className='block mx-auto mb-4 w-10 h-10 object-cover' />
                            : history.action === 'payment' ? <img src="/images/history/payment.png" alt="payment" className='block mx-auto mb-4 w-10 h-10 object-cover' />
                            : history.action === 'suspend' ? <img src="/images/history/suspend.png" alt="suspend" className='block mx-auto mb-4 w-10 h-10 object-cover' />
                            : history.action === 'active' ? <img src="/images/history/active.png" alt="cancel" className='block mx-auto mb-4 w-10 h-10 object-cover' />
                            : <img src="/images/history/completed.png" alt="completed" className='block mx-auto mb-4 w-10 h-10 object-cover' />
                          }
                        </div>
                        <div className='flex justify-center items-center mb-3'>
                          <span className='py-1 px-3 rounded-full text-xs text-white shadow-sm'
                                style={{ background: history.action === 'payment' ? "#65a30d" : getOrderStatus?.find(status => status.id === history?.nextStatus)?.color }}>
                            {history.action === 'payment' ? 'Payment' : getOrderStatus?.find(status => status.id === history?.nextStatus)?.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
            </div>
          </div>
          {/* <div className='mt-10 mb-4 flex justify-center items-center'>
            <button className='w-48 mx-3 py-3 px-6 rounded-md text-white font-bold focus:outline-none'
                    style={{ backgroundColor: currentColor }}
                    type='submit'
                    onClick={(e) => handleSubmit(e)}>
              Save
            </button>
            <button className='w-48 mx-3 py-3 px-6 rounded-md bg-white borderfont-bold focus:outline-none'
                    style={{ borderColor: currentColor, color: currentColor }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/crm/customers');
                    }}>
              Close
            </button>
          </div> */}
      </div>
      <OrderAction show={actionModal} close={() => setActionModal(false)} id={selectedOrderId} total={customer?.orders?.find(ord => ord._id === selectedOrderId)?.tax ? customer?.orders?.find(ord => ord._id === selectedOrderId)?.totalTaxed : customer?.orders?.find(ord => ord._id === selectedOrderId)?.total} />
      <PrintOrder show={showPrintModal} close={() => setShowPrintModal(false)} order={customer?.orders?.find(order => order._id === selectedOrderId)} type={printType} />
    </div>
  );
};
export default CustomerView;
