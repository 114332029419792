import * as TYPES from "./types";
//Ecommerce Orders
// Get Ecommerce Orders
export const getEcommerceOrdersRequest = (payload) => ({
  type: TYPES.GET_ECOMMERCE_ORDERS_REQUEST,
  payload,
});
export const getEcommerceOrdersReceive = (payload) => ({
  type: TYPES.GET_ECOMMERCE_ORDERS_RECEIVE,
  payload,
});
//Get Ecommerce Order
export const getEcommerceOrderRequest = (payload) => ({
  type: TYPES.GET_ECOMMERCE_ORDER_REQUEST,
  payload,
});
export const getEcommerceOrderReceive = (payload) => ({
  type: TYPES.GET_ECOMMERCE_ORDER_RECEIVE,
  payload,
});
//Create Ecommerce Order
export const createEcommerceOrderRequest = (payload, navigate) => ({
  type: TYPES.CREATE_ECOMMERCE_ORDER_REQUEST,
  payload,
  navigate,
});
export const createEcommerceOrderReceive = (payload) => ({
  type: TYPES.CREATE_ECOMMERCE_ORDER_RECEIVE,
  payload,
});
//Update Ecommerce Order
export const updateEcommerceOrderRequest = (payload, navigate) => ({
  type: TYPES.UPDATE_ECOMMERCE_ORDER_REQUEST,
  payload,
  navigate,
});
export const updateEcommerceOrderReceive = (payload) => ({
  type: TYPES.UPDATE_ECOMMERCE_ORDER_RECEIVE,
  payload,
});
//Delete Ecommerce Order
export const deleteEcommerceOrderRequest = (id) => ({
  type: TYPES.DELETE_ECOMMERCE_ORDER_REQUEST,
  id,
});
export const deleteEcommerceOrderReceive = (id) => ({
  type: TYPES.DELETE_ECOMMERCE_ORDER_RECEIVE,
  id,
});