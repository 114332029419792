import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Purshaces
//Get Purshaces
export function* getPurshacesSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getPurshacesRequest, payload);
    yield put(ACTIONS.getPurshacesReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}
//Get Product Purshaces
export function* getProductPurshacesSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getProductPurshacesRequest, payload);
    yield put(ACTIONS.getProductPurshacesReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Purshace
export function* getPurshaceSaga({ payload }) {
  try {
    const { data } = yield call(APIS.getPurshaceRequest, payload);
    yield put(ACTIONS.getPurshaceReceive(data));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Create Purshace
export function* createPurshaceSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.createPurshaceRequest, payload);
    yield put(ACTIONS.createPurshaceReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/crm/purshaces');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Update Purshace
export function* updatePurshaceSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.updatePurshaceRequest, payload);
    yield put(ACTIONS.updatePurshaceReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/crm/purshaces');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Delete Purshace
export function* deletePurshaceSaga({ id }) {
  try {
    yield call(APIS.deletePurshaceRequest, id);
    yield put(ACTIONS.deletePurshaceReceive(id));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* purshacesSagas() {
  yield takeLatest(TYPES.GET_PURSHACES_REQUEST, getPurshacesSaga);
  yield takeLatest(TYPES.GET_PRODUCT_PURSHACES_REQUEST, getProductPurshacesSaga);
  yield takeLatest(TYPES.GET_PURSHACE_REQUEST, getPurshaceSaga);
  yield takeLatest(TYPES.CREATE_PURSHACE_REQUEST, createPurshaceSaga);
  yield takeLatest(TYPES.UPDATE_PURSHACE_REQUEST, updatePurshaceSaga);
  yield takeLatest(TYPES.DELETE_PURSHACE_REQUEST, deletePurshaceSaga);
}


