import * as TYPES from "./types";

const initialState = {
  notifications_list: [],
  notifications_count: 0,
  notifications_page: 0,
  notifications_total: 0,
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    //Get Notifications
    case TYPES.GET_NOTIFICATIONS_RECEIVE: {
      return {
        ...state,
        notifications_list: action.payload.data,
        notifications_count: action.payload.headers["x-pagination-count"],
        notifications_page: action.payload.headers["x-pagination-page"],
        notifications_total: action.payload.headers["x-pagination-total"],
      }
    }
    //Seen Notifications
    case TYPES.SEEN_NOTIFICATIONS_RECEIVE: {
      return {
        ...state,
        notifications_list: action.payload.data,
        notifications_count: action.payload.headers["x-pagination-count"],
        notifications_total: action.payload.headers["x-pagination-total"],
        notifications_page: action.payload.headers["x-pagination-page"],
      }
    }
    default:
      return state
  }  
}
