import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  // dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Get Events
export function* getEventsSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getEventsRequest, payload);
    yield put(ACTIONS.getEventsReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* eventsSagas() {
  yield takeLatest(TYPES.GET_EVENTS_REQUEST, getEventsSaga);
}


