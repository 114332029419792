import { axiosInstance } from "../../network";
//Ecommerce Orders
//Get Ecommerce Orders
export const getEcommerceOrdersRequest = async (payload) => {
  return await axiosInstance.get(`api/ecommerce/orders`, { params: payload });
}
//Get Ecommerce Order
export const getEcommerceOrderRequest = async (payload) => {
  return await axiosInstance.get(`api/ecommerce/orders/${payload}`);
}
//Create Ecommerce Order
export const createEcommerceOrderRequest = async (payload) => {
  return await axiosInstance.post(`api/ecommerce/orders`, payload,);
} 
//Update Ecommerce Order
export const updateEcommerceOrderRequest = async (payload) => {
  return await axiosInstance.patch(`api/ecommerce/orders/${payload.id}`, payload);
}
//Delete Ecommerce Order
export const deleteEcommerceOrderRequest = async (id) => {
  return await axiosInstance.delete(`api/ecommerce/orders/${id}`);
}