import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Projects
//Get Projects
export function* getProjectsSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getProjectsRequest, payload);
    yield put(ACTIONS.getProjectsReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Project
export function* getProjectSaga({ payload }) {
  try {
    const { data } = yield call(APIS.getProjectRequest, payload);
    yield put(ACTIONS.getProjectReceive(data));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Suspend Project
export function* suspendProjectSaga({ id }) {
  try {
    const {data} = yield call(APIS.suspendProjectRequest, id);
    yield put(ACTIONS.suspendProjectReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Active Project
export function* activeProjectSaga({ id }) {
  try {
    const {data} = yield call(APIS.activeProjectRequest, id);
    yield put(ACTIONS.activeProjectReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Close Project
export function* closeProjectSaga({ id }) {
  try {
    const {data} = yield call(APIS.closeProjectRequest, id);
    yield put(ACTIONS.closeProjectReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Get My Projects
export function* getMyProjectsSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getMyProjectsRequest, payload);
    yield put(ACTIONS.getMyProjectsReceive({ data, headers }));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* projectsSagas() {
  yield takeLatest(TYPES.GET_PROJECTS_REQUEST, getProjectsSaga);
  yield takeLatest(TYPES.GET_PROJECT_REQUEST, getProjectSaga);
  yield takeLatest(TYPES.SUSPEND_PROJECT_REQUEST, suspendProjectSaga);
  yield takeLatest(TYPES.ACTIVE_PROJECT_REQUEST, activeProjectSaga);
  yield takeLatest(TYPES.CLOSE_PROJECT_REQUEST, closeProjectSaga);
  yield takeLatest(TYPES.GET_MY_PROJECTS_REQUEST, getMyProjectsSaga);
}


