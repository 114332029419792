import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminHomePage from "./adminHomePage";
import RevenderHomePage from "./revenderHomePage";
import CustomerHomePage from "./customerHomePage";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="m-2 md:m-6 p-2 md:p-4">
      {user?.role === 1 && <AdminHomePage />}
      {user?.role === 6 && <CustomerHomePage />}
      {user?.role === 7 && <RevenderHomePage />}
    </div>
  );
};

export default Home;
