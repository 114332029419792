import * as TYPES from "./types";

const initialState = {
  orders: [],
  order: {},
  pageCount: 0,
  currentPage: 0,
  totalCount: 0,
};

export default function ecommerceReducer(state = initialState, action) {
  switch (action.type) {
    //Get Orders
    case TYPES.GET_ECOMMERCE_ORDERS_RECEIVE: {
      return {
        ...state,
        orders: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalCount: action.payload.headers["x-pagination-total"],
      }
    }
    //Get Ecommerce Order
    case TYPES.GET_ECOMMERCE_ORDER_RECEIVE: {
      return {
        ...state,
        order: action.payload,
      }
    }
    //Create Ecommerce Order
    case TYPES.CREATE_ECOMMERCE_ORDER_RECEIVE: {
      return {
        ...state,
        orders: [...state.orders, action.payload],
      }
    }
    //Update Ecommerce Order
    case TYPES.UPDATE_ECOMMERCE_ORDER_RECEIVE: {
      return {
        ...state,
        orders: state.orders.map(order => {
          if (order.id === action.payload.id) {
            return action.payload;
          }
          return order;
        }
        ),
      }
    }
    //Delete Ecommerce Order
    case TYPES.DELETE_ECOMMERCE_ORDER_RECEIVE: {
      return {
        ...state,
        orders: state.orders.filter(order => order.id !== action.id),
      }
    }
    default:
      return state
  }  
}
