import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Suppliers
//Get Suppliers
export function* getSuppliersSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getSuppliersRequest, payload);
    yield put(ACTIONS.getSuppliersReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Supplier
export function* getSupplierSaga({ payload }) {
  try {
    const { data } = yield call(APIS.getSupplierRequest, payload);
    yield put(ACTIONS.getSupplierReceive(data));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Create Supplier
export function* createSupplierSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.createSupplierRequest, payload);
    yield put(ACTIONS.createSupplierReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/crm/suppliers');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Update Supplier
export function* updateSupplierSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.updateSupplierRequest, payload);
    yield put(ACTIONS.updateSupplierReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/crm/suppliers');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Delete Supplier
export function* deleteSupplierSaga({ id }) {
  try {
    yield call(APIS.deleteSupplierRequest, id);
    yield put(ACTIONS.deleteSupplierReceive(id));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* suppliersSagas() {
  yield takeLatest(TYPES.GET_SUPPLIERS_REQUEST, getSuppliersSaga);
  yield takeLatest(TYPES.GET_SUPPLIER_REQUEST, getSupplierSaga);
  yield takeLatest(TYPES.CREATE_SUPPLIER_REQUEST, createSupplierSaga);
  yield takeLatest(TYPES.UPDATE_SUPPLIER_REQUEST, updateSupplierSaga);
  yield takeLatest(TYPES.DELETE_SUPPLIER_REQUEST, deleteSupplierSaga);
}


