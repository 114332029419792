import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineDropbox, AiOutlineFileProtect } from 'react-icons/ai';
import { useStateContext } from '../../contexts/ContextProvider';
import NotAuthorized from '../../components/NotAuthorized';
import { deleteProductRequest, getProductsRequest } from '../../store/products/actions';
import Pagination from '../../components/Pagination';
import { settingsData } from '../../data/dummy';
import SinglePurshace from './SinglePurshace';

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products, pageCount, currentPage, totalCount } = useSelector(state => state.products);
  const [confirmModal, setConfirmModal] = useState(false);
  const [singlePurshaceModal, setSinglePurshaceModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const { currentColor } = useStateContext();
  const { user } = useSelector(state => state.auth);
  const [categories, setCategories] = useState([]);
  const [payload, setPayload] = useState({
    search: '',
    page: 0,
  });
 
  useEffect(() => {
    if (user?.role < 3) {
      dispatch(getProductsRequest(payload));
    }
  }, [dispatch, payload, user?.role]);

  useEffect(() => {
    if (products.length > 0) {
      let categoriesList = [];
      products.forEach(product => {
        if (!categoriesList.includes(product.category)) {
          categoriesList.push(product.category);
        }
      }
      );
      setCategories(categoriesList);
    }
  } , [products]);
    
  const handleDeleteProduct = () => {
    dispatch(deleteProductRequest(selectedProductId));
    setConfirmModal(false);
  }

  const onPageChange = (page) => {
    if (page.selected !== (currentPage + 1)) {
      setPayload({ ...payload, page: page.selected });
    }
  }

  if (user?.role > 2) {
    return <NotAuthorized />
  }

  return (
    <div className="m-2 md:m-6 p-2 md:p-6">
      <div className="flex justify-between items-center mt-20 sm:mt-0">
        <p className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	" 
            style={{ color: currentColor }}>
             {totalCount} Products
        </p>
        <MdOutlineLibraryAdd className="text-3xl	cursor-cell"
                          style={{ color: currentColor }}
                          onClick={() => navigate("/crm/products/add")} />
      </div>
      <div className='flex justify-end items-center py-4'>
        <input type="text" className="w-1/3 sm:w-1/4 px-4 py-3 bg-gray-100 focus:outline-none" placeholder="Search by name" value={payload.search} onChange={(e) => setPayload({ ...payload, search: e.target.value})} />
      </div>
      <div className='table_container'>
        <table className='w-full rounded-md table-auto'>
          <thead className='border border-gray-100 bg-gray-100'>
            <tr>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-left'>Category</th>
              <th className='px-4 py-3 text-sm text-left'>Product</th>
              <th className='px-4 py-3 text-sm text-center'>Stock</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-center'>Unit</th>
              <th className='hidden sm:table-cell px-4 py-3 text-sm text-right'>Price</th>
              <th className='px-4 py-3 text-sm'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              categories.map(category =>
                <>
                  {products.filter((pro) => pro.category === category).map((product, index) => (
                    <tr key={product._id}>
                      {index === 0 && <td rowSpan={products.filter(pro => pro.category === category)?.length} 
                          className='hidden sm:table-cell text-xs sm:text-sm text-left px-4 py-3 border border-gray-100 dark:border-gray-500 dark:text-white'>
                            {category}
                      </td>}
                      <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 text-xs sm:text-sm dark:text-white'>{product.name}</td>
                      <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 text-xs sm:text-sm dark:text-white text-center'><b>{product.stock || 0}</b></td>
                      <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 text-xs sm:text-sm dark:text-white text-center'>{product.unit}</td>
                      <td className='hidden sm:table-cell border border-gray-100 dark:border-gray-500 px-4 py-1 text-xs sm:text-sm dark:text-white text-right'><b>{product.price}</b> {settingsData.organization_currency}</td>
                      <td className='border border-gray-100 dark:border-gray-500 px-4 py-1 text-xs sm:text-sm flex justify-center items-center h-full'>
                        <AiOutlineFileProtect className="mx-1 my-3 text-2xl text-pink-500 hover:text-pink-800 cursor-pointer" 
                                          onClick={() => navigate(`/crm/purshaces/product/${product._id}`)} />
                        <AiOutlineDropbox className="mx-1 my-3 text-2xl text-purple-500 hover:text-purple-800 cursor-pointer"
                                          onClick={() => {
                                            setSelectedProductId(product);
                                            setSinglePurshaceModal(true)
                                            }} />
                        <AiOutlineEdit className="mx-1 my-3 text-2xl text-green-500 hover:text-green-800 cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(`/crm/products/edit/${product?._id}`);
                                        }} />
                        <AiOutlineDelete className="mx-1 my-3 text-2xl text-red-500 hover:text-red-800 cursor-pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedProductId(product?._id);
                                          setConfirmModal(true);
                                        }} />
                      </td>
                    </tr>
                  ))}
                </>
              )
            }
          </tbody>
        </table>
        <Pagination
         pageCount={pageCount}
         onPageChange={(page) => onPageChange(page)}
        />
      </div>

      <SinglePurshace show={singlePurshaceModal} close={() => setSinglePurshaceModal(false)} product={selectedProductId} />

      <div className={`flex justify-center items-center w-screen h-screen fixed top-0 left-0 transition-all bg-black bg-opacity-25 ${confirmModal ? 'opacity-100 pointer-events-auto	' : 'opacity-0 pointer-events-none'}`}>
        <div className="w-400 p-8 bg-white shadow-lg rounded-md">
          <p className='text-lg text-bold mb-6'>Confirm Delete?</p>
          <div className="flex justify-end">
            <button className="mx-1 bg-transparent border border-blue-500 hover:border-blue-700 text-blue-500 hover:text-blue-700 font-bold py-2 px-6 rounded-md" onClick={() => setConfirmModal(false)}>
              Cancel
            </button>
            <button className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-md" onClick={() => handleDeleteProduct()}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
