//Ecommerce ORDERS
//Get Ecommerce Orders
export const GET_ECOMMERCE_ORDERS_REQUEST = "GET_ECOMMERCE_ORDERS_REQUEST";
export const GET_ECOMMERCE_ORDERS_RECEIVE = "GET_ECOMMERCE_ORDERS_RECEIVE";
//Get Ecommerce Order
export const GET_ECOMMERCE_ORDER_REQUEST = "GET_ECOMMERCE_ORDER_REQUEST";
export const GET_ECOMMERCE_ORDER_RECEIVE = "GET_ECOMMERCE_ORDER_RECEIVE";
//Create Ecommerce Order
export const CREATE_ECOMMERCE_ORDER_REQUEST = "CREATE_ECOMMERCE_ORDER_REQUEST";
export const CREATE_ECOMMERCE_ORDER_RECEIVE = "CREATE_ECOMMERCE_ORDER_RECEIVE";
//Update Ecommerce Order
export const UPDATE_ECOMMERCE_ORDER_REQUEST = "UPDATE_ECOMMERCE_ORDER_REQUEST";
export const UPDATE_ECOMMERCE_ORDER_RECEIVE = "UPDATE_ECOMMERCE_ORDER_RECEIVE";
//Delete Ecommerce Order
export const DELETE_ECOMMERCE_ORDER_REQUEST = "DELETE_ECOMMERCE_ORDER_REQUEST";
export const DELETE_ECOMMERCE_ORDER_RECEIVE = "DELETE_ECOMMERCE_ORDER_RECEIVE";