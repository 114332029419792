import * as TYPES from "./types";
//Projects
// Get Projects
export const getProjectsRequest = (payload) => ({
  type: TYPES.GET_PROJECTS_REQUEST,
  payload,
});
export const getProjectsReceive = (payload) => ({
  type: TYPES.GET_PROJECTS_RECEIVE,
  payload,
});
//Get Project
export const getProjectRequest = (payload) => ({
  type: TYPES.GET_PROJECT_REQUEST,
  payload,
});
export const getProjectReceive = (payload) => ({
  type: TYPES.GET_PROJECT_RECEIVE,
  payload,
});
//Suspend Project
export const suspendProjectRequest = (id) => ({
  type: TYPES.SUSPEND_PROJECT_REQUEST,
  id,
});
export const suspendProjectReceive = (payload) => ({
  type: TYPES.SUSPEND_PROJECT_RECEIVE,
  payload,
});
//Active Project
export const activeProjectRequest = (id) => ({
  type: TYPES.ACTIVE_PROJECT_REQUEST,
  id,
});
export const activeProjectReceive = (payload) => ({
  type: TYPES.ACTIVE_PROJECT_RECEIVE,
  payload,
});
//Close Project
export const closeProjectRequest = (id) => ({
  type: TYPES.CLOSE_PROJECT_REQUEST,
  id,
});
export const closeProjectReceive = (payload) => ({
  type: TYPES.CLOSE_PROJECT_RECEIVE,
  payload,
});
//Get My Projects
export const getMyProjectsRequest = (payload) => ({
  type: TYPES.GET_MY_PROJECTS_REQUEST,
  payload,
});
export const getMyProjectsReceive = (payload) => ({
  type: TYPES.GET_MY_PROJECTS_RECEIVE,
  payload,
});