import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useStateContext } from '../../../contexts/ContextProvider';
import NotAuthorized from '../../../components/NotAuthorized';
import { getEcommerceOrderRequest, createEcommerceOrderRequest, updateEcommerceOrderRequest } from '../../../store/ecommerce/actions';
import moment from 'moment';
import { getSuppliersList, getShippersList } from '../../../data/dummy';
import { MdOutlineLocalShipping, MdCloudDone, MdOutlineCancel, MdOutlinePaid, MdOutlineAssignmentReturn } from 'react-icons/md';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { GrDropbox } from 'react-icons/gr';
const EOrderView = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { order } = useSelector(state => state.ecommerce);
  const { currentColor } = useStateContext();

      /* id: null,
      date: "",
      customer: "",
      city: "",
      status: "",
      product_name: "",
      product_ref: "",
      supplier: 1,
      buy_price: 0,
      sell_price: 0,
      shipper: 1,
      has_shipping_tax: true,
      is_collective_shipping: false,
      swap_breakage: false,
      returned: false,
      shipped: true,
      paid: false,
      completed: false,
      cancelled: false,
      shipper_payment: null,
      supplier_payment: null, */
   
  useEffect(() => {
    if (id) {
      dispatch(getEcommerceOrderRequest(id));
    }
  }, [dispatch, id]);

  if (user?.role > 2) {
    return <NotAuthorized />
  }
    
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-main-dark-bg bg-transparent rounded-3xl">
      <form  className="w-full mx-auto py-10 px-20 bg-slate-100 bg-gradient-to-tr from-slate-100 to-slate-400 rounded-md dark:from-slate-500 dark:to-slate-700">
          <h3 className='mb-10 mx-auto text-center text-3xl font-bold dark:text-white text-slate-600' 
              style={{ color: currentColor }}>Order View</h3>
          <div className='flex justify-between items-start'>
            <div className='w-1/2 p-4'>
                <div className='border-2 border-dashed rounded-md p-4'>
                    <h4 className='text-xl text-bold mb-4'>Order Infos</h4>
                    <div><span>Order Date:</span><span>{moment(order?.date).format("DD/MM/YYYY")}</span></div>
                    <div><span>Customer:</span><span>{order?.customer}</span></div>
                    <div><span>City:</span><span>{order?.city}</span></div>
                    <div><span>Status:</span><span>{order?.status}</span></div>
                    <div><span>Product Name:</span><span>{order?.product_name}</span></div>
                    <div><span>Product Ref:</span><span>{order?.product_ref}</span></div>
                </div>
            </div>
          </div>
      </form>
    </div>
  );
};
export default EOrderView;
