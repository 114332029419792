import { axiosInstance } from "../../network";
//Purshaces
//Get Purshaces
export const getPurshacesRequest = async (payload) => {
  return await axiosInstance.get(`api/purshaces`, {params: payload});
}
//Get Product Purshaces
export const getProductPurshacesRequest = async (payload) => {
  return await axiosInstance.get(`api/purshaces/product`, {params: payload});
}
//Get Purshace
export const getPurshaceRequest = async (payload) => {
  return await axiosInstance.get(`api/purshaces/${payload}`);
}
//Create Purshace
export const createPurshaceRequest = async (payload) => {
  return await axiosInstance.post(`api/purshaces`, payload);
} 
//Update Purshace
export const updatePurshaceRequest = async (payload) => {
  return await axiosInstance.patch(`api/purshaces/${payload.id}`, payload);
}
//Delete Purshace
export const deletePurshaceRequest = async (id) => {
  return await axiosInstance.delete(`api/purshaces/${id}`);
}