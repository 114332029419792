import { call, put, takeLatest } from "redux-saga/effects";
import * as APIS from "./apis";
import * as ACTIONS from "./actions";
import * as TYPES from "./types";
import {
  dispatchSnackbarError,
  dispatchSnackbarSuccess,
} from "../../utils/Shared";

//Products
//Get Products
export function* getProductsSaga({ payload }) {
  try {
    const { data, headers } = yield call(APIS.getProductsRequest, payload);
    yield put(ACTIONS.getProductsReceive({data, headers}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Get Product
export function* getProductSaga({ payload }) {
  try {
    const { data } = yield call(APIS.getProductRequest, payload);
    yield put(ACTIONS.getProductReceive(data));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Create Product
export function* createProductSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.createProductRequest, payload);
    yield put(ACTIONS.createProductReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/crm/products');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Update Product
export function* updateProductSaga({ payload, navigate }) {
  try {
    const { data } = yield call(APIS.updateProductRequest, payload);
    yield put(ACTIONS.updateProductReceive(data));
    dispatchSnackbarSuccess("success");
    setTimeout(() => {
      navigate('/crm/products');
    } , 1000);
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Delete Product
export function* deleteProductSaga({ id }) {
  try {
    yield call(APIS.deleteProductRequest, id);
    yield put(ACTIONS.deleteProductReceive(id));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Single Purshace
export function* singlePurchaseSaga({ payload }) {
  try {
    const { data } = yield call(APIS.singlePurchaseRequest, payload);
    yield put(ACTIONS.singlePurchaseReceive(data));
    dispatchSnackbarSuccess("success");
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

//Multiple Purshace
export function* multiplePurchaseSaga({ payload }) {
  try {
    yield call(APIS.multiplePurchaseRequest, payload);
    yield put(ACTIONS.getProductsRequest({page: 1, search: ''}));
  } catch (err) {
    console.log(err?.response?.data);
    dispatchSnackbarError(err.response?.data);
  }
}

export function* productsSagas() {
  yield takeLatest(TYPES.GET_PRODUCTS_REQUEST, getProductsSaga);
  yield takeLatest(TYPES.GET_PRODUCT_REQUEST, getProductSaga);
  yield takeLatest(TYPES.CREATE_PRODUCT_REQUEST, createProductSaga);
  yield takeLatest(TYPES.UPDATE_PRODUCT_REQUEST, updateProductSaga);
  yield takeLatest(TYPES.DELETE_PRODUCT_REQUEST, deleteProductSaga);
  yield takeLatest(TYPES.SINGLE_PURCHASE_REQUEST, singlePurchaseSaga);
  yield takeLatest(TYPES.MULTIPLE_PURCHASE_REQUEST, multiplePurchaseSaga);
}


