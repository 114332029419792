import { combineReducers } from "redux";
import auth from "./auth/reducer";
import users from "./users/reducer";
import loader from "./loader/reducer";
import snackbar from "./snackbar/reducer";
import notifications from "./notifications/reducer";
import events from "./events/reducer";
import ecommerce from "./ecommerce/reducer";
import customers from "./customers/reducer";
import services from "./services/reducer";
import orders from "./orders/reducer";
import projects from "./projects/reducer";
import payments from "./payments/reducer";
import products from "./products/reducer";
import suppliers from "./suppliers/reducer";
import purshaces from "./purshaces/reducer";  
import appointments from "./appointments/reducer";

export default combineReducers({
  snackbar,
  loader,
  auth,
  users,
  notifications,
  events,
  ecommerce,
  customers,
  services,
  orders,
  projects,
  payments,
  products,
  suppliers,
  purshaces,
  appointments
});
