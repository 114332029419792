import React from 'react'
import { useStateContext } from '../contexts/ContextProvider';
//import { GrEdit } from 'react-icons/gr';
import { settingsData } from '../data/dummy.js';
import { AiOutlineSetting } from 'react-icons/ai';
import { BsBoxSeam } from 'react-icons/bs';

function Settings() {
  const { currentColor } = useStateContext();

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-main-dark-bg bg-white rounded-3xl">
      <div className="flex flex-start items-center">
        <p className="text-3xl font-extrabold tracking-tight dark:text-white text-slate-600	mb-10" 
            style={{ color: currentColor }}>
              Organization Settings
        </p>
      </div>
      <label className='block text-xl text-bold dark:text-white text-gray-800 mb-2'>General Settings</label>
      <div className='border rounded-md p-4'>
        <div className='flex justify-center items-center mb-6'>
              <div className='relative mx-auto w-3/5 cursor-pointer' onClick={() => document.getElementById('organizationLogo').click()}>
                <img src={settingsData.organization_logo} alt='logo' className='block w-36 h-36 rounded-full mx-auto border-2 border-gray-200 object-contain' />
                {/* <GrEdit className='absolute bottom-0 left-1/2 text-green-500' /> */}
                <input type='file' className='hidden' id='organizationLogo' />
              </div>
        </div>
        <div className='flex justify-between items-center mb-4'>
          <div className='w-1/2 px-4'>
            <label className='block text-sm dark:text-gray-200 text-gray-600'>Organization Name</label>
            <input type='text' 
                  className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                  placeholder='Organization Name'
                  name='organization_name'
                  defaultValue={settingsData.organization_name}
                  readOnly={true}
                />

          </div>
          <div className='w-1/2 px-4'>
            <label className='block text-sm dark:text-gray-200 text-gray-600'>Organization Email</label>
            <input type='text' 
                  className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                  placeholder='Organization Email'
                  name='organization_email'
                  defaultValue={settingsData.organization_email}
                  readOnly={true}
                />
          </div>
        </div>
        <div className='flex justify-between items-center mb-4'>
            <div className='w-1/2 px-4'>
              <label className='block text-sm dark:text-gray-200 text-gray-600'>Organization Phone</label>
              <input type='text' 
                    className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                    placeholder='Organization Phone'
                    name='organization_phone'
                    defaultValue={settingsData.organization_phone}
                    readOnly={true}
                />
            </div>
            <div className='w-1/2 px-4'>
              <label className='block text-sm dark:text-gray-200 text-gray-600'>Organization Address</label>
              <input type='text' 
                    className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                    placeholder='Organization Address'
                    name='organization_address'
                    defaultValue={settingsData.organization_address}
                    readOnly={true} 
                />
            </div>
        </div>
        <div className='flex justify-between items-center mb-4'>
            <div className='w-1/2 px-4'>
              <label className='block text-sm dark:text-gray-200 text-gray-600'>Fiscal Number</label>
              <input type='text' 
                    className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                    placeholder='Fiscal Number' 
                    name='fiscal_number'
                    defaultValue={settingsData.fiscal_number}
                />
            </div>
            <div className='w-1/2'>
              <div className='flex items-start'>
                <div className='w-1/2 px-4'>
                  <label className='block text-sm dark:text-gray-200 text-gray-600'>Tax Name</label>
                  <input type='text' 
                        className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                        placeholder='Fiscal Number' 
                        name='tax_name'
                        defaultValue={settingsData.tax_name}
                    />
                </div>
                <div className='w-1/2 px-4'>
                  <label className='block text-sm dark:text-gray-200 text-gray-600'>Default Tax Value</label>
                  <input type='text' 
                        className='w-full px-4 py-3 bg-gray-100 focus:outline-none' 
                        placeholder='Fiscal Number' 
                        name='tax_name'
                        defaultValue={settingsData.company_services_tax_factor}
                    />
                </div>
              </div>
            </div>
          </div>
      </div>
      <label className='block text-xl text-bold dark:text-white text-gray-800 mt-10 mb-2'>Modules</label>
      <div className='border rounded-md p-4'>
          {settingsData?.modules?.map((module) => (
            <div className={`relative w-full p-4 border-2 rounded-md mb-4 shadow-md ${module.status ? 'dark:bg-red-200' : 'dark:bg-gray-100'} ${module.status ? 'bg-gray-50' : 'bg-gray-300'}`} style={{ borderColor: module.color }}>
              <div className='flex flex-start items-center mb-6 text-3xl text-bold' style={{ color: module.color }}>
                {module.icon} <span className='ml-4 '>{module.name}</span>
              </div>
              <div className='flex flex-start items-center flex-wrap'>
                  {
                    module?.links?.map((link) => 
                      <div className='p-2'>
                        <div className='rounded-full py-2 px-4 flex justify-center items-center border-1 cursor-pointer' style={
                          link.status ? { borderColor: module.color, backgroundColor: module.color, color: "#EEE" } : { borderColor: module.color, backgroundColor: "#EEE", color: module.color }
                        }>
                          {link.icon} <span className='ml-2'>{link.name}</span>
                        </div>
                      </div>
                    )
                  }
              </div>
              {module.name === "CRM" && module.type === 'Services' && <AiOutlineSetting className='absolute right-4 top-4 text-xl' style={{ color: module.color }} />}
              {module.name === "CRM" && module.type === 'Products' && <BsBoxSeam className='absolute right-4 top-4 text-xl' style={{ color: module.color }} />}
            </div>
          ))}
      </div>
    </div>
  )
}

export default Settings