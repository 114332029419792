import * as TYPES from "./types";

const initialState = {
  events_list: [],
  pageCount: 0,
  currentPage: 0,
  totalCount: 0,
};

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    //Get Events
    case TYPES.GET_EVENTS_RECEIVE: {
      return {
        ...state,
        events_list: action.payload.data,
        pageCount: action.payload.headers["x-pagination-count"],
        currentPage: action.payload.headers["x-pagination-page"],
        totalCount: action.payload.headers["x-pagination-total"],
      }
    }
    default:
      return state
  }  
}
